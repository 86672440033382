import React, { useEffect, useState } from "react";
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import blog from "../images/blog.jpg";
import Footer from "../components/footer/Footer";
import BlogCard from "../components/blogcomponent/BlogCard";
import Navbar from "../components/navbar/Navbar";
import { Helmet } from 'react-helmet';
import Loader from "../components/pageloader/Loader";

const BlogPage = () => {
  const [isLoader, setIsLoader] = useState(true)
  const TITLE = 'Blog Autotisement';

  const imageUrls = ["https://res.cloudinary.com/dznncrjxh/image/upload/v1693547920/blog_1_1_adubxn.jpg"]; 

useEffect(() => {
  const preloadImages = async () => {
    const imagePromises = imageUrls.map((imageUrl) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = resolve;
      });
    });

    await Promise.all(imagePromises);
    setTimeout(() => { setIsLoader(false); }, 1000);

    // All images are loaded, hide the loader
  };

  preloadImages();
}, []);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsLoader(false);
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timeout);
  //   };

  // }, [])

  return (
    <>
      <Helmet>cl
        <title>{TITLE}</title>
      </Helmet>
      {
        isLoader ?
          <Loader/>
          :
          <>
            <Navbar navbarcss='fixed' page='blog' />
            <AboutBanner heading="BLOG" photo={"https://res.cloudinary.com/dznncrjxh/image/upload/v1693547920/blog_1_1_adubxn.jpg"} />
            <BlogCard />
            <Footer />
          </>

      }

    </>
  );
};

export default BlogPage;
