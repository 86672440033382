import React, { useEffect, useState } from "react";
import Loader from "../components/pageloader/Loader";
import Footer from "../components/footer/Footer";
import First from "../components/Blog/First";
import Navbar from "../components/navbar/Navbar";
import thirdBlogImg from '../images/Hindi-Blog-Covers-2023-09-11T115714.235.jpg'


const ThirdBlog = () => {
  const [loder, setLoder] = useState(true);

  const loading = () => {
    setTimeout(() => {
      setLoder(false);
    }, 1000);
  };

  useEffect(() => {
    loading();
  }, [loder]);
  return (
    <>
      {loder ? (
        <Loader />
      ) : (
        <>
          <Navbar navbarcss='fixed' page='blog' />
          <First
            mainheading={thirdBlogImg}
            heading="भाषा का महत्व: हिंदी दिवस के आयोजन में, हिंदी भाषा के महत्व को बढ़ावा देना चाहिए। इसके लिए व्यक्ति और समूहों को हिंदी के महत्व के बारे में जागरूक करने के लिए प्रोत्साहित किया जा सकता है"
            firstline=" हिंदी दिवस (Hindi Diwas) भारत में हर साल 14 सितंबर को मनाया जाता है। यह दिन महात्मा गांधी द्वारा हिंदी को भारतीय स्वतंत्रता संग्राम की भाषा के रूप में स्वीकार किया गया था, और इसलिए इस दिन को महत्वपूर्ण तरीके से मनाया जाता है"
            secondline="हिंदी समाचार और विशेषांक: आप समाचार चैनल या प्रिंट मीडिया के माध्यम से हिंदी दिवस के अवसर पर विशेष समाचार बुलेटिन्स और विशेषांक प्रकाशित कर सकते हैं, जो हिंदी भाषा के महत्व और विकास को दर्शाते हैं।"
            thirdline="काव्य पाठ और साहित्य अधिकारों का मान्यता: विशेष रूप से, काव्य पाठ (पोएट्री रीडिंग) और साहित्य का पठन करने के अवसरों का आयोजन करें, जिसमें हिंदी के महत्व को मन्यता दिलाया जा सकता है।"
            fourthline="सांस्कृतिक कार्यक्रम: स्कूलों और कॉलेजों में हिंदी दिवस के अवसर पर सांस्कृतिक कार्यक्रम आयोजित करें, जैसे कि कविता पाठ, नृत्य, और हिंदी साहित्य के प्रस्तावना कार्यक्रम।

            व्याख्यान और वेबिनार: हिंदी के महत्व को बताने के लिए विशेषज्ञों को बुलाकर व्याख्यान और वेबिनार आयोजित करें। यह विद्यार्थियों और उनके शिक्षकों के लिए जागरूकता बढ़ावा कर सकता है।"
            date="14.09.2023"
          />
          <Footer />
        </>
      )}
    </>
  );
};

export default ThirdBlog;
