import React from 'react';
import './cofounder.css';
// import imgaman from '../../images/AmanAgarwal.jpg';
import imgmanisha from '../../images/Manisha.jpeg';
import imggupta from '../../images/AmanGupta.jpg';

const cofoundersData = [
    {
        name: 'Aman Agarwal',
        role: 'Co-Founder',
        image: "https://res.cloudinary.com/dznncrjxh/image/upload/v1693547057/AmanAgarwal_t625wh.jpg",
        profileUrl: '/aman-agarwal',
    },
    {
        name: 'MANISHA PANDEY',
        role: 'Co-Founder',
        image: imgmanisha,
        profileUrl: '/manisha-pandey',
    },
    {
        name: 'Aman Gupta',
        role: 'Co-Founder',
        image: imggupta,
        profileUrl: '/aman-gupta',
    },
    // Add more cofounder data objects here if needed
];

const Cofounder = () => {
    return (
        <section className='cofounder'>
            <div className='container'>
                <div className='row'>
                    {cofoundersData.map(({ name, role, image, profileUrl }, index) => (
                        <div className='col-md-4' key={profileUrl}>
                            <a href={`${profileUrl}`} target="_blank" rel="noopener noreferrer">
                                <div className='card'>
                                    <img src={image} alt={`Cofounder ${name}`} />
                                    <div className='card-body'>
                                        <h3 className='text'>{name}</h3>
                                        <h6 className='text'>{role}</h6>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Cofounder;
