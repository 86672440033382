import React from 'react'
import './teamabout.css';


const Progress = ({ progress, content, bar }) => {
    return (
        <>
           <section className='progres'>
           <div className='progressbar' style={{marginBottom:'20px'}}>
           <div style={{ marginBottom: '6px' }}>
                <h6>{content}</h6>
            </div>
            <div className='progress ' style={{ marginBottom: '30px' }}>
                <div className='bar' style={{ width: `${progress}%` }}>
                    <p>{bar}</p>
                </div>
            </div>
           </div>
           </section>
        </>
    )
}

export default Progress