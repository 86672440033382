import React from "react";
import "./contacthero.css";

const ContactHero = () => {
  return (
    <>
      <section className="contact-hero">
      {/* <div className="logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div> */}
        <div className="container">
          <div className="contactherocontent">
            <h1>CONTACT US</h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactHero;
