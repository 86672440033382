import React from "react";
import "./teampage.css";

const First = (props) => {
  return (
    <>
      <div
        className="teambanner"
        style={{
          backgroundImage: `url(${props.photo})`,
        }}
      >
        <div className="content">
          <p>{props.intro}</p>
          <h1>{props.heading}</h1>
          <p>{props.paragraph}</p>
        </div>
      </div>
    </>
  );
};

export default First;
