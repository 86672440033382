import React from 'react';
import './progressBar.css'; // Import the CSS file with progress bar styles

const ProgressBar = ({ progress }) => {
  return (
    <div className="vertical-progress-bar">
        <div className="progress-bar" style={{ height: `${progress}%` }}></div>
        <div className="progress-track" style={{ height: `${(100 - progress)}%` }}></div>
    </div>
  );
};

export default ProgressBar;