import React, { useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import design from "../images/design.jpg";
import Navbar from "../components/navbar/Navbar";
import Designupload from "../components/designcomponent/Designupload";
import Sideupload from '../components/designcomponent/Sideupload';
import { Helmet } from 'react-helmet';
import Loader from "../components/pageloader/Loader";


const DesignPage = () => {
  const [isLoader, setIsLoader] = useState(true)

  const TITLE = 'Design Autotisement';
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoader(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };

  }, [])

  return (
    <>
    <Helmet>
       <title>{TITLE}</title>
     </Helmet>

     {isLoader?
     <Loader/>
    :
    <>
     <Navbar navbarcss='fixed' page='design'/>
     <AboutBanner heading="DESIGN" photo={"https://res.cloudinary.com/dznncrjxh/image/upload/v1693547068/design_ha47hx.jpg"} />
     <Designupload />
     <Sideupload />
     <Footer />
    </>}
    
   </>
  );
};

export default DesignPage;
