import React, { useState, useEffect } from "react";
import "./homeslider.css";
import Swiper from "swiper";

const Slider = () => {
  useEffect(() => {
    const swiper = new Swiper('.swiper-container', {
      direction: 'vertical',
      mousewheelControl: true,
      slidesPerView: 1,
    });
  }, []);

  return (
    <div className="swiper-container">
      <div className="swiper-wrapper">
        <div className="swiper-slide">Slide 1</div>
        <div className="swiper-slide">Slide 2</div>
        <div className="swiper-slide">Slide 3</div>
        <div className="swiper-slide">Slide 4</div>
      </div>
    </div>
  );
};

export default Slider;
