import React, { useEffect, useState } from "react";
import "./blogupload.css";
import { useNavigate } from "react-router-dom";


const BlogUpload = () => {
  const navigate=useNavigate()
  const [data, setData] = useState({});
 const handleSubmit = async (event) => {
  event.preventDefault();
  // console.log(data)
  try {
    const formData = new FormData(); 
    formData.append("BlogTitle", data.BlogTitle);
    formData.append("titleContent", data.titleContent);
    formData.append("blogHeading", data.blogHeading);
    formData.append("firstline", data.firstline);
    formData.append("secondline", data.secondline);
    formData.append("thirdline", data.thirdline);
    formData.append("fourthline", data.fourthline);
    formData.append("image", event.target["image"].files[0]);

    const response = await fetch("https://autotisement.in/api/v1/blog", {
      method: "POST",
      headers: {
        "Authorization":localStorage.getItem("adminToken")
      },
      body: formData, 
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    console.log("Blog post created successfully:", result);
    setData({});
    navigate("/blog")

    // window.location.reload();
  } catch (error) {
    console.error("Error creating blog post:", error);
  }
};


  const handleChange = (event) => {
    const { name, value, type } = event.target;
    // console.log(name,value)
    setData({
      ...data,
      [name]: value,
    });
  };


  useEffect(()=>{

  },[])
 

  return (
    <>
      <section className="blogupload">
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h1 className="mb-3">Upload Blog</h1>
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="blog-title" className="form-label">
                      Blog Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="blog-title"
                      name="BlogTitle"
                      required
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="blog-image" className="form-label">
                      Blog Title Image  <span style={{ color: "red" }}>Image size should be less 100kb</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="blog-image"
                      name="image"
                      required
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="blog-content" className="form-label">
                      Title Content
                    </label>
                    <textarea
                      className="form-control"
                      id="blog-content"
                      name="titleContent"
                      rows="5"
                      required
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <label htmlFor="blog-content" className="form-label">
                      Blog Heading
                    </label>
                    <textarea
                      className="form-control"
                      id="blog-Heading"
                      name="blogHeading"
                      rows="5"
                      required
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <label htmlFor="blog-content" className="form-label">
                      Blog Firstline
                    </label>
                    <textarea
                      className="form-control"
                      id="blog-firstline"
                      name="firstline"
                      rows="5"
                      required
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <label htmlFor="blog-content" className="form-label">
                      Blog secondline
                    </label>
                    <textarea
                      className="form-control"
                      id="blog-secondline"
                      name="secondline"
                      rows="5"
                      required
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <label htmlFor="blog-content" className="form-label">
                      Blog thirdline
                    </label>
                    <textarea
                      className="form-control"
                      id="blog-thirdline"
                      name="thirdline"
                      rows="5"
                      required
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <label htmlFor="blog-content" className="form-label">
                      Blog fourthline
                    </label>
                    <textarea
                      className="form-control"
                      id="blog-fourthline"
                      name="fourthline"
                      rows="5"
                      required
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-dark w-100 fw-bold text-center"
                        >
                          Upload Blog
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogUpload;