import React, { useEffect, useState } from 'react'
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import Footer from "../components/footer/Footer";
import crs3 from "../images/crs3.jpg";
import Loader from "../components/pageloader/Loader";
import Navbar from '../components/navbar/Navbar'
import { Helmet } from 'react-helmet';
import PagesLink from '../components/pageslink/PagesLink';
import { Link } from 'react-router-dom';
import csrSubImg from '../images/CSR-new.jpg'


const CsrPage = () => {
    const [isLoader, setIsLoader] = useState(true)
    const TITLE = 'Csr Autotisement';

    const imageUrls = [crs3]
    useEffect(() => {
      const preloadImages = async () => {
        const imagePromises = imageUrls.map((imageUrl) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = resolve;
          });
        });
    
        await Promise.all(imagePromises);
        setTimeout(() => { setIsLoader(false); }, 1000);
        };
    
      preloadImages();
    }, []);


    
    return (
        <>
            {isLoader ? (
                <Loader />
            ) : (
                <>
                    <Helmet>
                        <title>{TITLE}</title>
                    </Helmet>
                    <Navbar navbarcss='fixed' page='about' />
                    <AboutBanner heading="CSR " photo={crs3} />
                    <section className="about-content">
                        <div className="container-fluid p-2">
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-4">
                                        <PagesLink />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="about_content ">
                                            <h3>CSR</h3>
                                            <p>
                                                Dealing with drivers as a reliable source of supply is not a straightforward transaction. It is
                                                important to earn their trust, which our mentor has done over the last 21 years. The unions turn
                                                to him for any legal, political, or socio-economic issues they may have to seek guidance and
                                                support, given his deep ties and knowledge in the transport sector as head of a large union
                                                <Link to='https://chaalakshakti.org/' target='_blank'> Chaalak Shakti</Link>.
                                            </p>

                                            <p>
                                                Alongside, we will use this opportunity to conduct CSR activities in partnership with Chaalak
                                                Shakti, a drivers-union, to benefit the drivers-families. Our objective is to utilize a part of the
                                                funds thus generated for the elevation of drivers-families and facilitating their upliftment by two
                                                notches in their social/financial status.
                                            </p>
                                        </div>
                                        <div className="sub-heading-img">
                                            <img src={csrSubImg} alt="about-sub-head" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            )}
        </>
    )
}

export default CsrPage