import React, { useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import Image from "../images/banner7.jpg";
import CareerContent from "../components/careercomponent/CareerContent";
import ContactForm from "../components/contactcomponent/ContactForm";
import Navbar from "../components/navbar/Navbar";
import { Helmet } from 'react-helmet';
import Loader from "../components/pageloader/Loader";
import CareerForm from "../components/careercomponent/CareerForm";


function CareerPage() {
  const [isLoader, setIsLoader] = useState(true)
  const TITLE = 'Career Autotisement';


  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoader(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };

  }, [])

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      {
        isLoader ?
          <Loader /> :
          <>
            <Navbar navbarcss='fixed' page='career' />
            <AboutBanner heading="CAREER" photo={Image} />
            <CareerContent />
            <CareerForm/>
            <Footer />
          </>
      }
    </>
  );
}

export default CareerPage;
