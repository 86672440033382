import React, { useEffect, useState } from 'react'
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import Footer from "../components/footer/Footer";
import whyUs_2 from "../images/wu.jpg";
import Loader from "../components/pageloader/Loader";
import Navbar from '../components/navbar/Navbar'
import { Helmet } from 'react-helmet';
import PagesLink from '../components/pageslink/PagesLink';
import whyUsSubImg from '../images/why-bottom.jpg'

const WhyUsPage = () => {
    const [isLoader, setIsLoader] = useState(true)
    const TITLE = 'Why Us Autotisement';

    const imageUrls = [whyUs_2]
    useEffect(() => {
      const preloadImages = async () => {
        const imagePromises = imageUrls.map((imageUrl) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = resolve;
          });
        });
    
        await Promise.all(imagePromises);
        setTimeout(() => { setIsLoader(false); }, 1000);
        };
    
      preloadImages();
    }, []);

    return (
        <>
            {isLoader ? (
                <Loader />
            ) : (
                <>
                    <Helmet>
                        <title>{TITLE}</title>
                    </Helmet>
      <Navbar navbarcss='fixed' page='about' />
                    <AboutBanner heading="WHY US" photo={whyUs_2} />
                    <section className="about-content">
                        <div className="container-fluid p-2">
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-4">
                                        <PagesLink />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="about_content ">
                                            <h3>WHY US</h3>
                                            <p>
                                                The synthesis of our deep relationship with drivers, an understanding of the startup ecosystem,
                                                design innovation, the role of technology, and a world scale vision is unparalleled. Likely
                                                competitors would approach us for guidance and collaboration.
                                            </p>

                                        </div>
                                        <div className="sub-heading-img">
                                            <img src={whyUsSubImg} alt="about-sub-head" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            )}
        </>
    )
}

export default WhyUsPage