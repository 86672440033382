import React, { useEffect, useState } from "react";
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import Footer from "../components/footer/Footer";
import TeamContent from "../components/teamcomponent/TeamContent";
import Teamhead from "../components/teamcomponent/Teamhead";
import Navbar from "../components/navbar/Navbar";
import { Helmet } from 'react-helmet';
import Cofounder from "../components/teamcomponent/Cofounder";
import Mentors from "../components/teamcomponent/Mentors";
import Loader from "../components/pageloader/Loader";
import team from "../images/team-banner.jpg";





const TeamPage = () => {
  const [isLoader, setIsLoader] = useState(true)
  const TITLE = 'Team Autotisement';
  const imageUrls = ["https://res.cloudinary.com/dznncrjxh/image/upload/v1693547791/team_jkqvbo.jpg",team]; // Add all image URLs to preload here


  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = imageUrls.map((imageUrl) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = resolve;
        });
      });
  
      await Promise.all(imagePromises);
      setTimeout(() => { setIsLoader(false); }, 1000);
      };
  
    preloadImages();
  }, []);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsLoader(false);
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timeout);
  //   };

  // }, [])
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      {isLoader ? <Loader /> :
        <>
          <Navbar navbarcss='fixed' page='team' />
          <AboutBanner heading="TEAM" photo={team} />
          <TeamContent
            first="A New Vision takes a"
            second="great well-rounded team"
            paragraph="Autotisement is certainly a disruption to the advertising industry. In order to implement it effectively and across the world, it takes a great team to be in place. Ideas will come from all sides which need to be translated into business by this great team."
          />
          <Teamhead intro="Introducing" leadership="Company Leadership" />
          <Cofounder />
          <Teamhead intro="Our" leadership="Mentors" />
          <Mentors />
          <TeamContent
            first="Core Values"
            paragraph="Empathy is at the core of our values. All our actions are guided by this and a set of other principles that include disruptive thinking, break-neck growth and integrity in our dealings with clients, solidly backed by relationships with our driver-partners."
          />
          <Footer />
        </>}

    </>
  );
};

export default TeamPage;
