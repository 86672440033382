import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./blogcard.css";
import image1 from "../../images/World-Oral-Health-Day (1).jpg";
import image2 from "../../images/world-environment.png";
import image3 from "../../images/Hindi-Diwas-1-350x196.avif";

// const cards = [
//   {
//     id: 1,
//     image: image1,
//     title: "World Oral Health Day 2023",
//     content:
//       "World Oral Health Day is observed on March 20th each year to raise awareness about the importance of good oral hygiene and its impact on overall health",
//     link: "/firstblog",
//     date: '10/5/2023'
//   },
//   {
//     id: 2,
//     image: image2,
//     title: "World Environment Day 2023",
//     content:
//       "World Environment Day is observed on June 5th each year to raise awareness and promote action for the protection and preservation of our environment",
//     link: "/secondblog",
//     date: '29/09/2023'
//   },
//   {
//     id: 3,
//     image:image3,
//     title: "हिंदी दिवस (Hindi Diwas) 2023",
//     content:
//       "हिंदी दिवस (Hindi Diwas) भारत में हर साल 14 सितंबर को मनाया जाता है। यह दिन महात्मा गांधी द्वारा हिंदी को भारतीय स्वतंत्रता संग्राम की भाषा के रूप में स्वीकार किया गया था",
//     link: "/thirdblog",
//     date: '10/5/2023'
//   },
//   {
//     id: 4,
//     image: "https://res.cloudinary.com/dznncrjxh/image/upload/v1693547068/design_ha47hx.jpg",
//     title: "World Oral Health Day 2023",
//     content:
//       "The International Day of Happiness was established by the United Nations in 2012 to recognize the importance of happiness.",
//     link: "/fourblog",
//     date: '10/5/2023'
//   },
//   {
//     id: 5,
//     image: "https://res.cloudinary.com/dznncrjxh/image/upload/v1693547068/design_ha47hx.jpg",
//     title: "World Oral Health Day 2023",
//     content:
//       "The International Day of Happiness was established by the United Nations in 2012 to recognize the importance of happiness.",
//     link: "/fourblog",
//     date: '10/5/2023'
//   },
//   {
//     id: 6,
//     image: "https://res.cloudinary.com/dznncrjxh/image/upload/v1693547068/design_ha47hx.jpg",
//     title: "World Oral Health Day 2023",
//     content:
//       "The International Day of Happiness was established by the United Nations in 2012 to recognize the importance of happiness.",
//     link: "/fourblog",
//     date: '10/5/2023'
//   },
// ];

const Card = ({ card }) => {
  const navigate = useNavigate();

  const handleCardClick = (id) => {
    // navigate(card.link);
    window.open(`/firstblog/${id}`, '_blank');
  };

  return (
    <section className="blogcard">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <img src={card.ImageUrl} alt={card.BlogTitle} />
              <div className="card-body blog-card-body">
                <h3>{card.BlogTitle}</h3>
                <p>{`${card.titleContent?.slice(0,150)}...`}</p>
                <div className="btndate">
                  <button onClick={()=>handleCardClick(card._id)}>Learn more</button>
                  <p>{card?.createdAt?.slice(0,10)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const CardList = () => {
  const [card,setCard]=useState([])
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch("https://autotisement.in/api/v1/all-blogs", {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result= await response.json();
        console.log(result);
        setCard(result);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [])
  return (
    <div className="card-list">
      {card.map((card) => (
        <Card key={card.id} card={card} />
      ))}
    </div>
  );
};

export default CardList;
