import React, { useEffect, useState } from "react";
import "./designupload.css";
// import sideImg from '../../images/autobackImg.jpeg'

const Designupload = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState(null);
  const [validDimension,setValidDimension]=useState("")

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    const reader = new FileReader();

    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;

      image.onload = () => {
        const { width, height } = image;
        // setImageDimensions({ width, height });
        const DPI=72
        const widthInInch=Math.floor(width/DPI)
        const heightInInch=Math.floor(height/DPI)
        setImageDimensions({widthInInch,heightInInch });
        if(width>=1296 && height>=1728){
          setValidDimension(true)
        }else{
          setValidDimension(false)
        }
        
      };
    };

    reader.readAsDataURL(file);
  };

  const UplodImage=(e)=>{
    e.preventDefault()
    console.log("set",validDimension,imageDimensions)
  }
  return (
    <>
      <section className="designupload">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="head">
                <h2 className=" pt-5 text-center ">
                  You can upload your design and view how it will look on an
                  autorickshaw.
                </h2>
                <h5 className="text-center">When the graphic is uploaded, it will show on an auto below immediately.</h5>
                <br/>
                <p className="graphic-size text-center">
                  <h3>Side view</h3>
                  Graphic size should be 18 inch wide x 24 inch high
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="upload">
                <input type="file" onChange={handleImageUpload} />
                <button onClick={UplodImage}>Upload Side Image</button>
              </div>
              {!validDimension && imageDimensions && (
                    <p>
                    {/* <p>width; {imageDimensions?.widthInInch}inch  height {imageDimensions?.heightInInch}inch</p> */}
                    {(
                      <p className="img-error-msg text-center">Image demension in not appropriate. Please Follow the specified demension</p>
                    )}
                  </p>
                  )}
            </div>
            <div className="col-md-12">
              <div className="upload-image-side p-3">
              {/* <div>
                <img src={sideImg} alt="side img"/>
              </div> */}
                <div
                  className="sidebanner"
                  
                    style={{
                      backgroundImage: `url(${validDimension? selectedImage:null})`,
                    }}
                  
                  
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Designupload;
