import React, { useEffect, useState } from "react";
import ContactHero from "../components/contactcomponent/ContactHero";
import Footer from "../components/footer/Footer";
import ContactForm from "../components/contactcomponent/ContactForm";
import Map from "../components/map/Map";
import Navbar from "../components/navbar/Navbar";
import { Helmet } from 'react-helmet';
import Loader from "../components/pageloader/Loader";


const ContactPage = () => {
  const TITLE = 'Contact Autotisement';

  const [loader, setLoader] = useState(true);
  const imageUrls = []; 

  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = imageUrls.map((imageUrl) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = resolve;
        });
      });
  
      await Promise.all(imagePromises);
      setTimeout(() => { setLoader(false); }, 1000);
    };
  
    preloadImages();
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      {
        loader?
        <Loader/>
        :
        <><Navbar navbarcss='fixed' page='contact' />
        <ContactHero />
        <ContactForm />
        <Map />
        <Footer />
        </>
      }
      
    </>
  );
};

export default ContactPage;
