import React, { useEffect, useState } from "react";
import First from "../TeamComponent/First";
import secbanner from "../images/Manisha.jpeg";
import TeamAbout from "../TeamComponent/TeamAbout";
import Footer from "../components/footer/Footer";
import Loader from "../components/pageloader/Loader";
import Navbar from "../components/navbar/Navbar";
import Socialmedia from "../components/teamcomponent/Socialmedia";
import Matter from "../components/teamcomponent/Matter";
import Secondbanner from '../images/Manishadeppression.jpg'
import Progress from "../components/teamcomponent/Progress";



const SecondPage = () => {
  const [loder, setLoder] = useState(true);

  const loading = () => {
    setTimeout(() => {
      setLoder(false);
    }, 1000);
  };

  useEffect(() => {
    loading();
  }, [loder]);
  return (
    <>
      {loder ? (
        <Loader />
      ) : (
        <>
          <Navbar navbarcss='fixed' page='team' />
          <First
            photo={secbanner}
            intro="Introducing"
            heading=" MANISHA PANDEY"
            paragraph="Co-Founder"
          />
          <TeamAbout
            teamname=" ABOUT MANISHA PANDEY"
            about="Manisha is a psychologist and is responsible for all people-centric activities. She enrolls and engages drivers and handles HR function in the company's building-up stage. Given her vast experience, the team turns to her for advice on matters of strategy and driver-related matters. She approaches challenges with a positive mindset, embracing innovation and seeking out creative solutions."
            thought={`"We learn,unlearn and relearn to learn again \n and keep learning"`} 
            
            writter=" - Manisha Pandey"
          />
          <Socialmedia
            email="manisha@autotisement.in "
            phone="+91-9582675009"
            facebook="https://www.facebook.com/mangokudi "
            linkedin="https://www.linkedin.com/in/mangokudi"
          />
          <Matter photo={Secondbanner} Matter='LIFE OF THE POOR' heading='PAIN WITH FINANCIAL
           STRUGGLE IS DEBILITATING' className="manisha-pandey"/>
          <section className='Teamabout'>
            <div className='container'>
              <h3>WHERE DO I STAND</h3>
              <div className='row'>
                <div className='col-md-12'>
                  <Progress progress={100} content="Listening" bar='Made for it' />
                  <Progress progress={95} content="Judging people" bar='Excellent' />
                  <Progress progress={20} content="Technology" bar='A Survivor' />
                  <Progress progress={95} content="Psychological counselling" bar='Excellent' />
                  <Progress progress={90} content="Execution" bar='Very Good' />
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default SecondPage;
