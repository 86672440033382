import React from "react";
import "./first.css";

const First = (props) => {
  return (
    <>
      <section className="firstblog">
        <div className="blogcontent">
          <div className="blog-img" >
            <img src={props.mainheading}/>
          </div>
          <div className="postdate"></div>
        </div>
        <div className="blogdetails">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content">
                  <h1>{props.heading}</h1>
                  <p>{props.firstline}</p>
                  <p>{props.secondline}</p>
                  <p>{props.thirdline}</p>
                  <p>{props.fourthline}</p>
                </div>
              </div>
              <div className="date">
                <p>{props.date}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default First;
