import React, { useEffect, useState } from "react";
import Loader from "../components/pageloader/Loader";
import First from "../components/Blog/First";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import secondBlogImg from '../images/enviroonment-day.jpg'

const SecondBlog = () => {
  const [loder, setLoder] = useState(true);

  const loading = () => {
    setTimeout(() => {
      setLoder(false);
    }, 1000);
  };

  useEffect(() => {
    loading();
  }, [loder]);
  return (
    <>
      {loder ? (
        <Loader />
      ) : (
        <>
          <Navbar navbarcss='fixed' page='blog' />
          <First
            mainheading={secondBlogImg}
            heading="Each year, World Environment Day has a specific theme. Check the official World Environment Day website or the United Nations Environment Programme (UNEP) for the current year's theme, and align your content accordingly"
            firstline="Share key facts and statistics about environmental issues to highlight the urgency of the situation. These could include data on climate change, deforestation, pollution, and biodiversity loss."
            secondline="  Discuss the most pressing environmental challenges facing the world today, such as climate change, air and water pollution, plastic waste, and habitat destruction"
            thirdline=" Provide practical tips and advice on how individuals can adopt more sustainable lifestyles. This can include reducing energy consumption, conserving water, reducing waste, and supporting eco-friendly products and practices"
            fourthline="Highlight successful conservation initiatives and efforts taking place around the world. Showcase organizations, communities, or individuals who are making a positive impact on the environment"
            date="5.06.2023"
          />
          <Footer />
        </>
      )}
    </>
  );
};

export default SecondBlog;
