import React from 'react';
import './cofounder.css';
import imgrakesh from '../../images/rakesh-org.jpg';
import imgprahalad from '../../images/PrahladKakkar.jpeg';
import imglawyer from '../../images/Lawyer-home.png';
import imgkushal from '../../images/KushalSanghvi.png';

const mentorData = [
    {
        name: 'RAKESH AGARWAL',
        role: 'Mentor',
        image: imgrakesh,
        profileUrl: '/mentor-rakesh',
    },
    {
        name: 'PRAHLAD KAKKAR',
        role: 'Mentor',
        image: imgprahalad,
        profileUrl: '/mentor-prahalad-kakkar',
    },
    {
        name: 'LAWYER',
        role: 'Mentor',
        image: imglawyer,
        profileUrl: '/mentor-lawyer',
    },
    {
        name: 'KUSHAL SANGHI',
        role: 'Mentor',
        image: imgkushal,
        profileUrl: '/mentor-kushal-sanghi',
    },
    // Add more mentors data objects here if needed
];

const Cofounder = () => {
    return (
        <section className='cofounder'>
            <div className='container'>
                <div className='row'>
                    {mentorData.map((mentor, index) => (
                        <div className='col-md-3' key={index}>
                            <a href={mentor.profileUrl} target="_blank" rel="noopener noreferrer">
                                <div className='card'>
                                    <img src={mentor.image} alt={`Mentor ${index}`} />
                                    <div className='card-body'>
                                        <h3 className='text'>{mentor.name}</h3>
                                        <h6 className='text'>{mentor.role}</h6>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Cofounder;
