import React, { useEffect, useState } from "react";
import "./designupload.css";

const Designupload = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState(null);
  const [validDimension, setValidDimension] = useState("")

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    const reader = new FileReader();

    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;

      image.onload = () => {
        const { width, height } = image;
        setImageDimensions({ width, height });

        // const imageRatio = width / height;
        // const desiredRatio = 3 / 2;
        // console.log("image",Math.abs(imageRatio - desiredRatio) < 0.01)

        if (width >=2592 && height>=1728) {
          setValidDimension(true)
        } else {
          setValidDimension(false)
        }
      };
    };
    reader.readAsDataURL(file);
  };


  // useEffect(()=>{
  // setValidDimension("")
  // },[handleImageUpload])
  return (
    <>
      <section className="designupload">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="head">
                <h2 className="pt-5  text-center text-sm-left">
                  You can upload your design and view how the back view will look on an autorickshaw
                </h2>
                <h5 className="text-center">When the graphic is uploaded, it will show on an auto below immediately.</h5>
                <br />
                <p className="graphic-size text-center">
                  <h3 className="text-center">Back view</h3>
                  Graphic size should be 36 inch wide x 24 inch high
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="upload">
                <input type="file" onChange={handleImageUpload} />
                <button>Upload back Image</button>
              </div>
            </div>
            {!validDimension && imageDimensions && (

              <p>
                {/* <p>width; {imageDimensions?.width}  height {imageDimensions?.height}</p> */}
                {(
                  <p className="img-error-msg text-center">Image demension in not appropriate. Please Follow the specified demension</p>
                )}
              </p>
            )}
            <div className="col-md-12">
              <div className="upload-image mt-3">
                <div
                  className="banner mt-3"
                  style={{
                    backgroundImage: `url(${validDimension ? selectedImage : null})`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Designupload;
