import React, { useEffect, useState } from "react";
import "./navbar.css";
import { Link, NavLink } from "react-router-dom";
import Logo from '../../images/logo.png'

function Navbar(props) {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    function handleOutsideClick(event) {
      if (event.target.closest(".menu") || event.target.closest(".menu-icon")) {
        return;
      }
      setIsMenuOpen(false);
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isMenuOpen]);

  return (
    <>
      {props.page ? <div className='patch' /> : <></>}
      <header className={(isHeaderFixed ? props.navbarcss : "").concat(' ', props.page ? 'pageview': '')}>
        <div className="logo">
          <Link to="/home"> <img src={Logo} alt="logo" /> </Link>
        </div>
        <nav className={`menu ${isMenuOpen ? "open" : ""}`}>
          <ul>
            <li className={props.page=='service' ? 'page' : ''}>
              <NavLink to="/service">Service</NavLink>
            </li>
            {/* <li className={props.page=='design' ? 'page' : ''}>
              <Link to="/design">Design</Link>
            </li> */}
            {/* <li className={props.page=='price' ? 'page' : ''}>
              <NavLink to="/price">Price</NavLink>
            </li> */}
            <li className={props.page=='about' ? 'page' : ''}>
              <Link to="/about">About</Link>
            </li>
            <li className={props.page=='team' ? 'page' : ''}>
              <Link to="/team">Team</Link>
            </li>
            {/* <li className={props.page=='blog' ? 'page' : ''}>
              <Link to="/blog">Blog</Link>
            </li> */}
            <li className={props.page=='career' ? 'page' : ''}>
              <Link to="/career">Career</Link>
            </li>
            <li className={props.page=='contact' ? 'page' : ''}>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
        <div
          className={`menu-icon ${isMenuOpen ? "open" : ""}`}
          onClick={handleMenuClick}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
    </>
  );
}

export default Navbar;
