import "./App.css";
import React, { useEffect, useState, } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Loader from "./components/pageloader/Loader";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import CareerPage from "./pages/CareerPage";
import TeamPage from "./pages/TeamPage";
import DesignPage from "./pages/DesignPage";
import BlogPage from "./pages/BlogPage";
import PricePage from "./pages/PricePage";
import ServicePage from "./pages/ServicePage";
import FirstPage from "./TeamPage/FirstPage";
import SecondPage from "./TeamPage/SecondPage";
import ThirdPage from "./TeamPage/ThirdPage";
import FourPage from "./TeamPage/FourPage";
import FivePage from "./TeamPage/FivePage";
import SevenPage from './TeamPage/SevenPage'
import FirstBlog from "./Blogs/FirstBlog";
import SecondBlog from "./Blogs/SecondBlog";
import ThirdBlog from "./Blogs/ThirdBlog";
import FourBlog from "./Blogs/FourBlog";
import LandingPage from "./pages/LandingPage";
import HomeSlider from "./components/homeslider/HomeSlider";
import BlogUpload from "./components/blogupload/BlogUpload";
import AdminLogin from "./components/adminlogin/AdminLogin";
import Concernpage from "./pages/Concernpage";
import NewCatagoryPage from "./pages/NewCatagoryPage";
import ValueAddedPage from "./pages/ValueAddedPage";
import WhyUsPage from "./pages/WhyUsPage";
import CsrPage from "./pages/CsrPage";
import Background from "./pages/Background";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SixPage from "./TeamPage/SixPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>

        {/* <Loader /> */}
        <ScrollToTop />
        <Routes>
          {/* <Route path="/" element={<HomePage />}></Route> */}
          <Route path="/" element={<LandingPage />}></Route>

          {/* <Route path="/https://www.autotisement.in/enrolment/"></Route> */}

          <Route path="/home" element={<LandingPage />}></Route>
          <Route path="/loader" element={<Loader />}></Route>
          <Route path="/about" element={<AboutPage />}></Route>
          <Route path="/contact" element={<ContactPage />}></Route>
          <Route path="/career" element={<CareerPage />}></Route>
          <Route path="/team" element={<TeamPage />}></Route>
          <Route path="/design" element={<DesignPage />}></Route>
          <Route path="/blog" element={<BlogPage />}></Route>
          <Route path="/price" element={<PricePage />}></Route>
          <Route path="/service" element={<ServicePage />}></Route>
          <Route path="/aman-agarwal" element={<FirstPage />}></Route>
          <Route path="/manisha-pandey" element={<SecondPage />}></Route>
          <Route path="/aman-gupta" element={<ThirdPage />}></Route>
          <Route path="/mentor-rakesh" element={<FourPage />}></Route>
          <Route path="/mentor-prahalad-kakkar" element={<FivePage />}></Route>
          <Route path="/mentor-lawyer" element={<SixPage />}></Route>
          <Route path="/mentor-kushal-sanghi" element={<SevenPage />}></Route>
          <Route path="/firstblog/:id" element={<FirstBlog />}></Route>
          <Route path="/secondblog" element={<SecondBlog />}></Route>
          <Route path="/thirdblog" element={<ThirdBlog />}></Route>
          <Route path="/fourblog" element={<FourBlog />}></Route>
          <Route path="/homeslider" element={<HomeSlider />}></Route>
          <Route path="/uploadblog" element={<BlogUpload />}></Route>
          <Route path="/adminlogin" element={<AdminLogin />}></Route>
          <Route path="/concern" element={<Concernpage />}></Route>
          <Route path="/catagory" element={<NewCatagoryPage />}></Route>
          <Route path="/value" element={<ValueAddedPage />}></Route>
          <Route path="/why" element={<WhyUsPage />}></Route>
          <Route path="/csr" element={<CsrPage />}></Route>
          <Route path="/background" element={<Background />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
