import React, { useEffect, useState } from "react";
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import Footer from "../components/footer/Footer";
import price from "../images/price.jpg";
import Price from "../components/pricecomponent/Price";
import Navbar from '../components/navbar/Navbar'
import Autotable from "../components/autotable/Autotable";
import { Helmet } from 'react-helmet';
import Loader from "../components/pageloader/Loader";



const PricePage = () => {
  const [isLoader, setIsLoader] = useState(true)
  const TITLE = 'Price Autotisement';

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoader(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };

  }, [])

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      {isLoader?<Loader/>:
      <>
       <Navbar navbarcss='fixed' page='price'/>
      <AboutBanner heading="PRICE" photo={"https://res.cloudinary.com/dznncrjxh/image/upload/v1693547673/price_1_ompe7v.jpg"} />
      <Price />
      <Autotable />
      <Footer />
      </>}
     
    </>
  );
};

export default PricePage;
