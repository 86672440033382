import React, { useEffect, useState } from "react";
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import Footer from "../components/footer/Footer";
import Service from "../images/service.jpg";
import Navbar from "../components/navbar/Navbar";
import ServiceContent from '../components/servicecomponent/ServiceContent'
import Autotable from "../components/autotable/Autotable";
import { Helmet } from 'react-helmet';
import Loader from "../components/pageloader/Loader";



const ServicePage = () => {
  const TITLE = 'Service Autotisement';
  const [isLoader, setIsLoader] = useState(true)


  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoader(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };

  }, [])
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      {isLoader ? <Loader /> :
        <>
          <Navbar navbarcss='fixed' page='service' />
          <AboutBanner heading="SERVICE " photo={"https://res.cloudinary.com/dznncrjxh/image/upload/v1693547017/service_1_vsmscy.jpg"} />
          <ServiceContent />
          <Autotable />
          <Footer />
        </>}

    </>
  );
};

export default ServicePage;
