import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import "./aboutcontent.css";
import PagesLink from "../pageslink/PagesLink";
import aboutSubImg from '../../images/about-bottom.jpg'

const AboutContent = () => {
  const imageUrls = [aboutSubImg]; 

  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = imageUrls.map((imageUrl) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = resolve;
        });
      });
  
      await Promise.all(imagePromises);
    };
  
    preloadImages();
  }, []);
  return (
    <>
      <section className="about-content">
        <div className="container-fluid p-2">
          <div className="container ">
            <div className="row">
              <div className="col-md-5">
                <PagesLink />
              </div>
              <div className="col-md-7">
                <div className="about_content ">
                  <h3>ABOUT</h3>
                  <p>
                    Autotisement is a social venture and is the only company
                    that offers next-gen backlit and digital auto ads, impacting
                    clients and drivers alike, and profitable from Day One.
                  </p>
                  <p>
                    We aim to expand the worldwide market multi-fold and corner
                    90% of it, thus ensuring a monopolistic position for 5
                    years. One of our mentors is an ad-guru.
                  </p>
                  <p>
                    Autotisement is conceived by 3 co-founders and one mentor.
                    Visit <Link to="/team">team</Link> page for details.
                    Additionally, we brought in two mentors later on.
                  </p>
                </div>
                <div className="sub-heading-img">
                  <img src={aboutSubImg} alt="about-sub-head" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutContent;
