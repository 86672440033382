import React, { useEffect, useState } from 'react'
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import Footer from "../components/footer/Footer";
import newCategoryImg from "../images/newCategoryImg.jpg";
import Loader from "../components/pageloader/Loader";
import Navbar from '../components/navbar/Navbar'
import { Helmet } from 'react-helmet';
import PagesLink from '../components/pageslink/PagesLink';
import newCategorySubImg from '../images/auto2.jpg'

const NewCatagoryPage = () => {
    const [isLoader, setIsLoader] = useState(true)
    const TITLE = 'New Catagory Autotisement';

    const imageUrls = [newCategoryImg,newCategorySubImg]
    useEffect(() => {
        const preloadImages = async () => {
            const imagePromises = imageUrls.map((imageUrl) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.src = imageUrl;
                    img.onload = resolve;
                });
            });

            await Promise.all(imagePromises);
            setTimeout(() => { setIsLoader(false); }, 1000);
        };

        preloadImages();
    }, []);

    return (
        <>
            {isLoader ? (
                <Loader />
            ) : (
                <>
                    <Helmet>
                        <title>{TITLE}</title>
                    </Helmet>
                    <Navbar navbarcss='fixed' page='about' />

                    <AboutBanner heading="NEW CATAGORY" photo={newCategoryImg} />
                    <section className="about-content">
                        <div className="container-fluid p-2">
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-4">
                                        <PagesLink />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="about_content ">
                                            <h3>NEW CATAGORY</h3>
                                            <p>
                                                We have modified the autorickshaw and replaced its hood (upper yellow portion) with one made
                                                of FRP (Fibre Reinforced Plastic) and made it backlit and stronger than steel. The next version will
                                                have TV-style digital displays directly controlled by clients enabling them to change the ads
                                                within seconds throughout the fleet - a paradigm shift in the OOH space, and making the vehicle
                                                itself an IoT device.
                                            </p>

                                        </div>
                                        <div className="sub-heading-img">
                                            <img src={newCategorySubImg} alt="about-sub-head" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            )}
        </>
    )
}

export default NewCatagoryPage