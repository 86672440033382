import React from 'react'
import './autotable.css'

const Autotable = () => {   
    return (
        <>
            <section className='autotable'>
                <div className="container">
                    <h2 className="cost-comparison b-5 mb-4 mt-5 text-center">Cost comparison with flex auto ads </h2>
                    <div className="table-responsive">
                        <table className="table  table-hover">
                            <thead>
                                <tr>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-label="content">Backlighting factor</td>
                                    <td data-label="price"className='text-end'>4 X</td>
                                </tr>
                                 <tr>
                                    <td data-label="content">Quality factor due to FRP Hood</td>
                                    <td data-label="price" className='text-end'>5 X</td>
                                </tr>
                                <tr>
                                    <td data-label="content">Verification factor</td>
                                    <td data-label="price"className='text-end'>2.5 X</td>
                                </tr>
                                <tr>
                                    <td data-label="content">Shelf-life factor</td>
                                    <td data-label="price"className='text-end'>3 X</td>
                                </tr>
                                <tr>
                                    <td data-label="content">Therefore, comparative advantage </td>
                                    <td data-label="price"className='text-end'>150 X</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                        <div class="table-responsive content">
                            <table class="table  table-hover">
                                <thead>
                                    <tr>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td data-label="content">Cost of per flex auto ad  </td>
                                        <td data-label="price"className='text-end'>500</td>
                                    </tr>
                                    <tr>
                                        <td data-label="content">Multiple of the above factors </td>
                                        <td data-label="price"className='text-end'> X 150</td>
                                    </tr>
                                    <tr>
                                        <td data-label="content">Effective cost per flex auto </td>
                                        <td data-label="price"className='text-end'>75,000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    <div className='row'>
                        <div class="table-responsive">
                            <table class="table  table-hover">
                                <thead>
                                    <tr>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td data-label="content">Autotisement’s normal price </td>
                                    <td data-label="price"className='text-end'> 5,500 – 14,000 </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='row'>
                        <div class="table-responsive">
                            <table class="table  table-hover">
                                <thead>
                                    <tr>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td data-label="content" className='text-center therefor'>Therefore, Autotisement cannot be compared with Flex Auto Ads</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>

            </section>
        </>
    )
}

export default Autotable