import React, { useEffect, useState } from "react";
import First from "../TeamComponent/First";
import firstbanner from "../images/AmanAgarwal_t625.jpg";
import TeamAbout from "../TeamComponent/TeamAbout";
import Footer from "../components/footer/Footer";
import Loader from "../components/pageloader/Loader";
import Navbar from "../components/navbar/Navbar";
import Socialmedia from "../components/teamcomponent/Socialmedia";
import Matter from "../components/teamcomponent/Matter";
import amanbanner from '../images/Aman-1.jpg';
import Teamabout from "../components/teamcomponent/Teamabout";

const FirstPage = () => {
  const [loder, setLoder] = useState(true);
  const imageUrls = [firstbanner, amanbanner]; 

useEffect(() => {
  const preloadImages = async () => {
    const imagePromises = imageUrls.map((imageUrl) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = resolve;
      });
    });

    await Promise.all(imagePromises);
    setTimeout(() => { setLoder(false); }, 1000);

    // All images are loaded, hide the loader
  };

  preloadImages();
}, []);

  // const loading = () => {
  //   setTimeout(() => { setLoder(false); }, 1000);
  // };
  // useEffect(() => { loading(); }, [loder]);

  return (
    <>
      {loder ? (<Loader />) : (
        <>
          <Navbar navbarcss='fixed' page='team' />
          <First
            photo={firstbanner}
            intro="Introducing"
            heading="AMAN AGARWAL"
            paragraph="Co-Founder"
          />
          <TeamAbout
            teamname=" ABOUT AMAN AGARWAL"
            about="Aman is an ardent Advocate and has an eye for detail. He is on the mission to finish what his father started in 2009, which was to harness the potential of omnipresent autorickshaws and revolutionize the auto-ads industry with the introduction of next-gen backlit and digital auto ads. Although He qualified as a lawyer, his true passions are philanthropy, and innovation."
            thought={`“Entrepreneurship is living a few years of your life like most people won't, so that you can spend the rest of your life like most people can't.”`}
            writter=" -Aman Agarwal "
          />
          <Socialmedia
            email="aman@autotisement.in "
    
            facebook="https://www.facebook.com/fastaman "
            linkedin="https://www.linkedin.com/in/fastaman"
          />
          <Matter className="aman-agrawal" photo={amanbanner} Matter='FAVOURITE SPOT' heading="TEL AVIV" />
          <Teamabout />
          <Footer />
        </>
      )}
    </>
  );
};

export default FirstPage;
