import React, { useState, useEffect } from "react";
import First from "../TeamComponent/First";
import sixbanner from "../images/KushalSanghvi.png";
import TeamAbout from "../TeamComponent/TeamAbout";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import Loader from "../components/pageloader/Loader";


const SixPage = () => {
    const [loader, setLoader] = useState(true);
    const imageUrls = [sixbanner];

    useEffect(() => {
        const preloadImages = async () => {
            const imagePromises = imageUrls.map((imageUrl) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.src = imageUrl;
                    img.onload = resolve;
                });
            });

            await Promise.all(imagePromises);
            setTimeout(() => { setLoader(false); }, 1000);
        };

        preloadImages();
    }, []);
    return (
        <>
            {loader ? (<Loader />) : (
                <>
                    <Navbar navbarcss='fixed' page='team' />
                    <First
                        photo={sixbanner}
                        intro="Introducing"
                        heading="KUSHAL SANGHI"
                        paragraph="Mentor"
                    />
                    <TeamAbout
                        teamname=" ABOUT KUSHAL SANGHI"
                        about="Apart from being the most down to earth and nurturing person in any 
        room, he is not only our coolest mentor, but also a media wizard with an abundant and 
        diverse experience of over 24 years. He has worked with numerous global groups in 
        senior leadership roles and has contributed to the media industry with his expertise in 
        branding, print, digital, television, OOH, content and also the invention of wireless and 
        telephony revenue with the pioneer show- Indian Idol. Dr. Kushal has a strong penchant 
        for Advertising media and communications Industry and that is what drove him to 
        Advertising and Media communication Industry.”"
                        thought="“Clients don’t care about the labour pains; they want to see the baby”"
                        writter=" - Kushal Sanghi"

                    />
                    <Footer />
                </>
            )}

        </>
    );
};

export default SixPage;
