import React,{useState,useEffect} from "react";
import First from "../TeamComponent/First";
import sixbanner from "../images/Lawyer.png";
import TeamAbout from "../TeamComponent/TeamAbout";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import Loader from "../components/pageloader/Loader";


const SixPage = () => {
  const [loader, setLoader] = useState(true);
  const imageUrls = [sixbanner]; 

useEffect(() => {
  const preloadImages = async () => {
    const imagePromises = imageUrls.map((imageUrl) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = resolve;
      });
    });

    await Promise.all(imagePromises);
    setTimeout(() => { setLoader(false); }, 1000);
  };

  preloadImages();
}, []);
  return (
    <>
    {loader?<Loader/>:
    <>
     <Navbar navbarcss='fixed' page='team' />
      <First
        photo={sixbanner}
        intro="Introducing"
        heading="LAWYER"
        paragraph="Mentor"
      />
      <TeamAbout
        teamname=" ABOUT WELL-KNOWN LAWYER"
        about="Our mentor is probably the best-known lawyer in the country, better known for his public 
        interest litigation done pro bono. He has also handled the cases of auto ads on our behalf 
        in the Delhi high court in the past. He is a constant fixture in matters of debates on 
        judicial reforms, the subject closest to his heart.”"
        thought="“Indeed, public scrutiny is desirable for good governance”"
        writter=" - Lawyer"
      />
     
      <Footer />
    </>}
     
    </>
  );
};

export default SixPage;
