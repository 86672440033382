import React from "react";
import "./teampage.css";

const TeamAbout = (props) => {
  const isAmanGupta = window.location.pathname === '/aman-gupta';
  return (
    <>
      <section className="teamabout">
        <div className="container">
          <div className="row">
            <h3>{props.teamname}</h3>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="teamaboutleft">
                <p>{props.about}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="teamaboutright">

                {isAmanGupta ?
                  <>
                    <pre>
                      <p>

                        {props.thought}
                      </p>

                    </pre>
                  </>
                    :
                    <>
                      <p>
                        {props.thought}
                      </p>
                    </>
                  }

                <span>{props.writter}</span>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default TeamAbout;
