import React, { useState, useRef, useEffect } from "react";
import "./contactform.css";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";


const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [location, setLocation] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [uploadCV, setUploadCV] = useState("")
  const [message, setMessage] = useState("");
  const [addToMailingList, setAddToMailingList] = useState(false);
  const [Nameborder, setNameBorder] = useState(true);
  const [emailBorder, setEmailBorder] = useState(true);
  const [mobileBorder, setMobileBorder] = useState(true);
  const [locationBorder, setLocationBorder] = useState(true);
  const [companyBorder, setCompanyBorder] = useState(true);
  const [websiteBorder, setWebsiteBorder] = useState(true);
  const [messageBorder, setMessageBorder] = useState(true);
  const [isDetailSubmit, setDetailSubmit] = useState(false);

  const [captchaResponse, setCaptchaResponse] = useState("");
  const captchaRef = useRef()
  const [captchaVerify, setCaptchVerify] = useState(false)

  const handleCaptchaChange = (response) => {
    setCaptchaResponse(response);
    // console.log(response)
    if (response) {
      setCaptchVerify(true)
    }
  };

  const valid = () => {
    if (name === "") {
      setNameBorder(false);
    } else {
      setNameBorder(true);
    }
  };

  const EmailValid = () => {
    if (
      email === "" ||
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    ) {
      setEmailBorder(false);

    } else {
      setEmailBorder(true);
    }
  };
  const MobileValida = () => {
    if (mobileNumber === "") {
      setMobileBorder(false);
    } else {
      setMobileBorder(true);
    }
  };

  const locationValid = () => {
    if (location === "") {
      setLocationBorder(false);
    } else {
      setLocationBorder(true);
    }
  };
  const companyValid = () => {
    if (company === "") {
      setCompanyBorder(false);
    } else {
      setCompanyBorder(true);
    }
  };
  const websiteValid = () => {
    if (website === "") {
      setWebsiteBorder(false);
    } else {
      setWebsiteBorder(true);
    }
  };
  const messageValid = () => {
    if (message === "") {
      setMessageBorder(false);
    } else {
      setMessageBorder(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name === "") {
      setNameBorder(false);
      alert("Name is required")
      return
    }
    if (
      email === "" ||
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    ) {
      setEmailBorder(false);
      alert("valid email is required")
      return
    }
    if (mobileNumber === "") {
      setMobileBorder(false);
    }
    if (location === "") {
      setLocationBorder(false);
    }
    if (company === "") {
      setCompanyBorder(false);
    }
    if (
      website === "" ||
      !/^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/.test(website)
    ) {
      setWebsiteBorder(false);
    }
    if (message === "") {
      setMessageBorder(false);
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobileNumber", mobileNumber);
    formData.append("location", location);
    formData.append("company", company);
    formData.append("website", website);
    formData.append("message", message);
    formData.append("file", uploadCV);

    // console.log(formData)
    let requestURL = "https://autotisement.in/api/v1/contact"
    // let requestURL="http://localhost:3001/api/v1/contact"
    try {
      const response = await axios.post(requestURL, formData);
      console.log(response)
      if (response?.data?.success === true) {
        alert("Form submitted successfully");
        setDetailSubmit(true)
        setName("")
        setEmail("")
        setLocation("")
        setMobileNumber("")
        setWebsite("")
        setCompany("")
        setMessage("")
        captchaRef.current.reset()

      } else {
        alert("Server responded with an error. Please try again later.");
      }
    } catch (error) {
      alert("Something is wrong. try again in sometime")
      return
    }
  };
  return (
    <>
      <div className="contactform">
        <div className="container">
          <div className="row">
            <div className="col-md-9 address-div">
            <section className="address-section">
                <div className="lavel">
                <strong className="address-lavel"> Address  </strong>
                </div>
                <div className="value">
                  <p>
                   Autotisement Adtech Pvt. Ltd<br />
                    A-50 Vikram Nagar<br />
                    Feroze Shah Kotla<br />
                    New Delhi – 110002
                  </p>
                </div>

              </section >
              <section className="address-section">
                <div className="lavel">
                  <strong className="address-lavel"> Email</strong>
                </div>
                <div className="value">
                  <p>
                  mail@autotisement.in
                  </p>
                </div>
              </section>
              <section className="address-section">
                <div className="lavel">
                  <strong className="address-lavel">Phone Number</strong>
                </div>
                <div className="value">
                  <p>
                  8826887766
                  </p>
                </div>
              </section>
            </div>
            <div className="col-md-3 ">
           
            </div>


            <div className="col-md-6">
              <h2>CONTACT</h2>
            </div>
            <div className="col-md-6">
              <div className="checkbox-wrapper">
                <div className="m-fieldset-field2">
                  <input
                    type="checkbox"
                    id="check"
                    className="style3"
                    checked={addToMailingList}
                    onChange={(event) =>
                      setAddToMailingList(event.target.checked)
                    }
                  />
                  <span aria-hidden="true" className="m-fieldset-bg2"></span>
                  <span aria-hidden="true" className="check-icon"></span>
                </div>
                <label for="check">
                  Add me to the Autotisment Mailing list
                </label>

              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="m-fieldset-field">
                <input
                  type="text"
                  required
                  id="fname"
                  value={name}
                  onBlur={valid}
                  onChange={(event) => setName(event.target.value)}
                />
                <span
                  aria-hidden="true"
                  className={`${Nameborder ? "m-fieldset-bg" : "redborder"}`}
                ></span>
                <span aria-hidden="true" className="m-fieldset-field__label">
                  Name*
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="m-fieldset-field">
                <input
                  type="email"
                  required
                  value={email}
                  onBlur={EmailValid}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <span
                  aria-hidden="true"
                  className={`${emailBorder ? "m-fieldset-bg" : "redborder"}`}
                ></span>
                <span aria-hidden="true" className="m-fieldset-field__label">
                  {`${(emailBorder || email === "") ? "Email*" : ""}`}
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="m-fieldset-field">
                <input
                  type="number"
                  required
                  // maxlength="10"
                  value={mobileNumber}
                  onBlur={MobileValida}
                  onChange={(event) => setMobileNumber(event.target.value)}
                />
                <span
                  aria-hidden="true"
                  className={`${mobileBorder ? "m-fieldset-bg" : "redborder"}`}
                ></span>
                <span aria-hidden="true" className="m-fieldset-field__label">
                  Phone Number
                </span>

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="m-fieldset-field">
                <input
                  type="text"
                  required
                  value={location}
                  onBlur={locationValid}
                  onChange={(event) => setLocation(event.target.value)}
                />
                <span
                  aria-hidden="true"
                  className="m-fieldset-bg"

                ></span>
                <span aria-hidden="true" className="m-fieldset-field__label">
                  Location
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="m-fieldset-field">
                <input
                  type="text"
                  required
                  value={company}
                  onBlur={companyValid}
                  onChange={(event) => setCompany(event.target.value)}
                />
                <span
                  aria-hidden="true"
                  className="m-fieldset-bg"

                ></span>
                <span aria-hidden="true" className="m-fieldset-field__label">
                  Company
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="m-fieldset-field">
                <input
                  type="text"
                  required
                  value={website}
                  onBlur={websiteValid}
                  onChange={(event) => setWebsite(event.target.value)}
                />
                <span
                  aria-hidden="true"
                  className="m-fieldset-bg"

                ></span>
                <span aria-hidden="true" className="m-fieldset-field__label">
                  Website
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="m-fieldset-field">
                <input
                  className="custom-file-input"
                  type="file"
                  placeholder="he"
                  accept=".pdf,.doc,.docx,.jpeg,.jpg,.png"
                  onChange={(event) => setUploadCV(event.target.files[0])}
                />
                <span
                  aria-hidden="true"
                  className="m-fieldset-bg"

                ></span>
                <span aria-hidden="true" className="selectedFile">
                  {uploadCV ? `Selected File- ${uploadCV.name.slice(0, 10)}...` : ""}
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="m-fieldset-field">
                <div className="recaptch-container" style={{ width: "100%" }}>
                  <ReCAPTCHA
                    // style={{width:"110%"}}
                    // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    sitekey="6LeCRAIoAAAAAGsJMzRJbRGepA3UJsybgKOU3i3T"
                    onChange={(response) => handleCaptchaChange(response)}
                    ref={captchaRef}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="m-fieldset-field inputmessage">
                <textarea
                  required
                  className="inputmessage"
                  value={message}
                  onBlur={messageValid}
                  onChange={(event) => setMessage(event.target.value)}
                ></textarea>
                <span
                  aria-hidden="true"
                  className="m-fieldset-bg"
                ></span>
                <span
                  aria-hidden="true"
                  className="m-fieldset-field__label textarea"
                >
                  Message
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className={captchaVerify ? `formbtnHvover` : "formbtn"} disabled={!captchaVerify}>
                <button onClick={handleSubmit} disabled={!captchaVerify}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
