import React, { useEffect, useState } from 'react'
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import Footer from "../components/footer/Footer";
import Loader from "../components/pageloader/Loader";
import Navbar from '../components/navbar/Navbar'
import { Helmet } from 'react-helmet';
import PagesLink from '../components/pageslink/PagesLink';
import infotainment from '../images/Value - Infotainment.jpg'
import seat from '../images/Seats.JPG'
import square4 from "../images/square4.jpg";
import uniform from '../images/Value - Uniform.JPG'
import messenger from '../images/Value - Messenger.jpg'
import Vending from '../images/Value - Vending machine.jpg'
import dopBox from '../images/drop-box-img.jpg'
import internalBrand from '../images/Inside-5.jpg'
import externalBrand from '../images/external-branding.jpg'




const ValueAddedPage = () => {
    const [isLoader, setIsLoader] = useState(true)
    const TITLE = 'Value Added Autotisement';


    const imageUrls = [square4,seat,infotainment,uniform,Vending,messenger,internalBrand,externalBrand]
    useEffect(() => {
      const preloadImages = async () => {
        const imagePromises = imageUrls.map((imageUrl) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = resolve;
          });
        });
    
        await Promise.all(imagePromises);
        setTimeout(() => { setIsLoader(false); }, 1000);
        };
    
      preloadImages();
    }, []);

    return (
        <>
            {isLoader ? (
                <Loader />
            ) : (
                <>
                    <Helmet>
                        <title>{TITLE}</title>
                    </Helmet>
                    <Navbar navbarcss='fixed' page='about' />
                    <AboutBanner heading="VALUE ADDED FEATURES" photo={square4} />
                    <section className="about-content">
                        <div className="container-fluid p-2">
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-4">
                                        <PagesLink />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="about_content ">
                                            <h3>VALUE ADDED FEATURES</h3>
                                            <h6>
                                                <p className='value-add-subHeading'>INFOTAINMENT SYSTEM</p>
                                            </h6>
                                            <div className="image">
                                                <img src={infotainment} alt="" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <p style={{padding:'20px 0 30px 0',fontSize:"22px"}}>Passenger operated aircraft-style digital displays for internal branding</p>
                                            <h6>
                                                <p className='value-add-subHeading'>BRANDED SEATS</p>
                                            </h6>
                                            <div className="image">
                                                <img src={seat} alt="" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <p style={{padding:'20px 0 30px 0' ,fontSize:"22px"}}>The seats can also be used as a branding space with printed upholstery bearing the brand’s logo
                                                or product display</p>
                                            <h6>
                                                <p className='value-add-subHeading'>BRANDED UNIFORM</p>
                                            </h6>
                                            <div className="image">
                                                <img src={uniform} alt="" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <p style={{padding:'20px 0 30px 0' ,fontSize:"22px"}}>The driver can wear a branded uniform carrying the brand’s logo that can be strategically placed,
                                                post a discussion with the concerned brand.</p>
                                            <h6>
                                                <p className='value-add-subHeading'>BRAND MESSENGER</p>
                                            </h6>
                                            <div className="image-brand-manager">
                                                <img src={messenger} alt="" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <p style={{padding:'20px 0 30px 0' ,fontSize:"22px"}}>
                                                At the start of a campaign, half a day’s training can be imparted to the drivers who can become
                                                an extension of the brand and carry its message to the passengers</p>
                                            <h6>
                                                <p className='value-add-subHeading'>VENDING MACHINE</p>
                                            </h6>
                                            <div className="image">
                                                <img src={Vending} alt="" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <p style={{padding:'20px 0 30px 0' ,fontSize:"22px"}}>A small vending machine that can be used for product sampling and testers for new product
                                                launch campaigns.</p>
                                            <h6>
                                                <p className='value-add-subHeading'>DROP-BOX</p>
                                            </h6>
                                            <div className="image-dropbox">
                                                <img src={dopBox} alt="" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <p style={{padding:'20px 0 30px 0'}}>A multi-purpose drop-box will be installed which can be used for promotion literature and
                                                product sampling.</p>
                                            <h6>
                                                <p className='value-add-subHeading'>OVERHEAD INTERNAL BRANDING</p>
                                            </h6>
                                            <div className="image">
                                                <img src={internalBrand} alt="" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <p style={{padding:'20px 0 30px 0' ,fontSize:"22px"}}>There is adequate space inside the cabin, over the driver's head for internal branding</p>
                                            <h6>
                                                <p className='value-add-subHeading'>OVER-THE-TOP EXTERNAL BRANDING</p>
                                            </h6>
                                            {/* <div className="image">
                                                <img src={externalBrand} alt="" style={{ width: '100%', height: '100%' }} />
                                            </div> */}
                                            <p style={{padding:'20px 0 30px 0' ,fontSize:"22px"}}>he exterior of the hood has been engineered in a way to provide for the placement of made-toscale product samples at the top of the auto. (Attractive option for introduction of a new
                                                product).</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            )}
        </>
    )
}

export default ValueAddedPage