import React from 'react'
import './pageslink.css'
import { NavLink } from 'react-router-dom';

const PagesLink = () => {
    return (
        <>
            <div className="about_btn">
                <NavLink
                    to="/about"
                    className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                    }
                >
                    01. ABOUT
                </NavLink>
                <NavLink
                    to="/concern"
                    className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                    }
                >
                    02. CONCERNS
                </NavLink>
                <NavLink
                    to="/catagory"
                    className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                    }
                >
                    03. NEW CATAGORY
                </NavLink>
                <NavLink
                    to="/value"
                    className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                    }
                >
                    04. VALUE ADDED FEATURES
                </NavLink>

                <NavLink
                    to="/why"
                    className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                    }
                >
                    05. WHY US
                </NavLink>
                <NavLink
                    to="/csr"
                    className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                    }
                >
                    06. CSR
                </NavLink>
                <NavLink
                    to="/background"
                    className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                    }
                >
                    07. BACKGROUND
                </NavLink>
            </div>
        </>
    )
}

export default PagesLink