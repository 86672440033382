import React, { useEffect, useState } from "react";
import First from "../TeamComponent/First";
import fourbanner from "../images/Rakesh.jpg";
import TeamAbout from "../TeamComponent/TeamAbout";
import Footer from "../components/footer/Footer";
import Loader from "../components/pageloader/Loader";
import Navbar from "../components/navbar/Navbar";
import Socialmedia from "../components/teamcomponent/Socialmedia";
import Matter from "../components/teamcomponent/Matter";
import Secondbanner from '../images/Rakeshmilky.jpg'
import Progress from "../components/teamcomponent/Progress";




const FourPage = () => {
  const [loader, setLoader] = useState(true);
  const imageUrls = [fourbanner, Secondbanner]; 

  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = imageUrls.map((imageUrl) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = resolve;
        });
      });
  
      await Promise.all(imagePromises);
      setTimeout(() => { setLoader(false); }, 1000);
    };
  
    preloadImages();
  }, []);

  // const loading = () => {
  //   setTimeout(() => {
  //     setLoder(false);
  //   }, 1000);
  // };

  // useEffect(() => {
  //   loading();
  // }, [loder]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
      <Navbar navbarcss='fixed' page='team' />
          <First
            photo={fourbanner}
            intro="Introducing"
            heading="RAKESH AGARWAL"
            paragraph="Mentor"
          />
          <TeamAbout
            teamname=" ABOUT RAKESH"
            about="Our Prime mentor has an incredible 35 years of experience under his belt. A dozen of those years were spent immersed in the exciting world of fashion, while another two dozen were dedicated to making a difference through social activism. He's an integral part of the global entrepreneurial community, particularly in the realm of innovation and technology-led startups. Here at Autotisement, Rakesh assumes the role of a trusted mentor, actively guiding our team and ensuring that we steer clear of any serious or fatal mistakes in running our operations. With his wealth of knowledge and expertise, we can confidently navigate the challenges that come our way, knowing that we have Rakesh's wisdom to rely on."
            thought={`“The future belongs to those who believe \n in the power of their dreams.”`}
            writter=" - Arthur Rakesh Agarwal"
          />
          <Socialmedia
            email="rakesh@autotisement.in "
            phone="+91-987322222"
            facebook="https://www.facebook.com/soulitician "
            linkedin="https://www.linkedin.com/in/soulitician"
          />
          <Matter className="h1" photo={Secondbanner} Matter='WE DON’T MATTER' title="" heading='बोल कहां इसमें तू है'/>
          <section className='Teamabout'>
            <div className='container'>
            <h3>WHERE DO I STAND</h3>
              <div className='row'>
                <div className='col-md-12'>
                  <Progress progress={100} content="Vision for humanity" bar='unlike anyone' />
                  <Progress progress={70} content="Execution" bar='unlike' />
                  <Progress progress={30} content="Judging people" bar='Lousty' />
                  <Progress progress={100} content="Empathy" bar='A World Rakesh Craves' />
                  <Progress progress={70} content="An all-rounder" bar='A Survivor' />
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default FourPage;
