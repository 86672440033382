import React from 'react';
import './socialmedia.css';
import { Link } from 'react-router-dom';

const extractIdFromUrl = (url) => {
    console.log(url)
    const pattern = /linkedin\.com\/in\/([A-Za-z0-9-_]+)/; // Extracts the last part after the last "/"
    const match = url.match(pattern);
    console.log(match)
    return match ? `/${match[1]}` : url;

};
const extractFbIdFromUrl = (url) => {
    const facebookPattern = /facebook\.com\/([A-Za-z0-9-_]+)/;
    const match = url.match(facebookPattern);
    return match ? `/${match[1]}` : url;
  };

const Socialmedia = (props) => {
    const { email, phone, facebook, linkedin } = props;

    const handleEmailClick = (e) => {
        if (email) {
            window.open(`mailto:${email}`, '_blank');
            e.preventDefault();
        }
    };

    const handlePhoneClick = (e) => {
        if (phone) {
            window.open(`tel:${phone}`, '_blank');
            e.preventDefault();
        }
    };

    return (
        <>
            <section className='socialmedia'>
                <div className='container'>
                <h3>Social and Contact</h3>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='media'>
                                {email ? (
                                    <a href={`mailto:${email}`} onClick={handleEmailClick}>
                                        <i className="fa-regular fa-envelope"></i> {email}
                                    </a>
                                ) : (
                                    <Link to={''}>
                                        <i className="fa-regular fa-envelope"></i> {' '}
                                        <Link to={'mail'}>{email}</Link>
                                    </Link>
                                )}
                            </div>
                        </div>
                        {phone && (
                        <div className='col-md-12'>
                            <div className='media'>
                                    <a href={`tel:${phone}`} onClick={handlePhoneClick}>
                                        <i className="fa-solid fa-phone"></i> {phone}
                                    </a>
                            </div>
                        </div>
                                )}

                        <div className='col-md-12'>
                            <div className='media'>
                                {facebook && (
                                    <a href={facebook} target="_blank" rel="noopener noreferrer">
                                        <i className="fa-brands fa-facebook-f"></i> {extractFbIdFromUrl(facebook)}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='media'>
                                {linkedin && (
                                    <a href={linkedin} target="_blank" rel="noopener noreferrer">
                                        <i className="fa-brands fa-linkedin"></i> {extractIdFromUrl(linkedin)}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Socialmedia;
