import React from "react";
import logo from "../../images/logo.png";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="footercol">
            <div className="row">
              <div className="col-md-5">
                <div className="left_footer">
                  <h4>
                    The world's first and only autorickshaw advertisement
                    company that offers backlit autos. Version 2
                    client-controlled digital displays coming soon (IoT).
                  </h4>
                </div>
              </div>
              <div className="col-md-3">
                <div className="right_footer">
                  <div className="icon">
                    <i className="fa-regular fa-envelope"></i>
                  </div>
                  <div className="footercontent">
                    <h5>Send your queries at: </h5>
                    <div className="footeremail">
                      <Link to="mailto:info@autotisement.in">
                        info@autotisement.in
                      </Link>
                      <br/>
                      <Link to="mailto:sales@autotisement.in">
                        sales@autotisement.in
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="right_footer">
                  <div className="icon">
                    <i className="fa-solid fa-location-dot"></i>
                  </div>
                  <div className="footercontent">
                    <h5>Autotisement Adtech Pvt. Ltd</h5>
                    <p>
                      A-50 Vikram Nagar, Feroze Shah Kotla
                    </p>

                    <p>New Delhi – 110002</p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-line"></div>
        <div className="container">
          <div className="row">
            <ul className="footer-nav">
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>
                <Link to="/service">Service</Link>
              </li>
              {/* <li>
                <Link to="/design">Design</Link>
              </li> */}
              {/* <li>
                <Link to="/price">Price</Link>
              </li> */}
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/team">Team</Link>
              </li>
              {/* <li>
                <Link to="/blog">Blog</Link>
              </li> */}
              <li>
                <Link to="/career">Career</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="social_link">
            <div className="link">
              <ul className="footer-social-link">
                <li>
                  <Link to="https://www.linkedin.com/company/autotisements/" target="_blank">
                    <i className="fa-brands fa-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/autotisement" target="_blank">
                    <i className="fa-brands fa-square-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://youtube.com/@Autotisement" target="_blank">
                    <i className="fa-brands fa-square-youtube"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/autotisement_in/" target="_blank">
                    <i className="fa-brands fa-square-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.facebook.com/Autotisement" target="_blank">
                    <i className="fa-brands fa-square-facebook"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footertext">
              <p>
                © 2023, Design by
                <Link to="https://www.otusone.com/"> Otusone</Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
