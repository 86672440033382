import React, { useState } from "react";
import "./adminlogin.css";
import { Link,useNavigate } from "react-router-dom";

function Login() {
  const navigate=useNavigate()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch("https://autotisement.in/api/v1/admin/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
  
      const data = await response.json();
      console.log(data)
      if (response.status === 200 && data) {
        localStorage.setItem("adminToken",data.token)
        window.alert("Login successful");
        setEmail("");
        setPassword("");
        console.log("Login successful");
        navigate("/uploadblog")
      } else {
        window.alert("Invalid credentials");
        console.log("Invalid credentials");
      }
    } catch (error) {
      console.error("Error during login:", error);
      window.alert("An error occurred during login.");
    }
  };
  

  return (
    <div className="login-container mt-5">
      <h2> Admin Login</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      
          <button type="submit" className="btn btn-primary custom-btn">
            Submit
          </button>
      
      </form>
    </div>
  );
}
export default Login;