import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import './price.css'

const marks = [
  {
    value: 20,
    // label: '20',
  },
  {
    value: 50,
    // label: '50',
  },
  {
    value: 100,
    // label: '100',
  },
  {
    value: 200,
    // label: '200',
  },
  {
    value: 300,
    // label: '300',
  },

  {
    value: 500,
    // label: '500',
  },

];
const months = [
  {
    value: 0.5,
    // label: '0.5',
  },
  {
    value: 1,
    // label: '1',
  },
  {
    value: 2,
    // label: '2',
  },
  {
    value: 3,
    // label: '3',
  },
  {
    value: 6,
    // label: '6',
  },
];


function valuetext(value) {
  return `${value}`;
}


const DiscreteSliderMarks = () => {
  const [autos, setAutos] = useState(20);
  const [tenure, setTenure] = useState(0.5);
  const [costPerAuto, setCostPerAuto] = useState(0)
  // const [totalCost, setTotalCost] = useState(0)



  const PayableAmount = [
    { tenure: .5, autos: 20, price: 14000 },
    { tenure: 1, autos: 20, price: 12000 },
    { tenure: 2, autos: 20, price: 11000 },
    { tenure: 3, autos: 20, price: 10000 },
    { tenure: 6, autos: 20, price: 9000 },

    { tenure: .5, autos: 50, price: 13500 },
    { tenure: 1, autos: 50, price: 11500 },
    { tenure: 2, autos: 50, price: 10500 },
    { tenure: 3, autos: 50, price: 9500 },
    { tenure: 6, autos: 50, price: 8000 },

    { tenure: .5, autos: 100, price: 13000 },
    { tenure: 1, autos: 100, price: 11000 },
    { tenure: 2, autos: 100, price: 10000 },
    { tenure: 3, autos: 100, price: 9000 },
    { tenure: 6, autos: 100, price: 7000 },

    { tenure: .5, autos: 200, price: 12500 },
    { tenure: 1, autos: 200, price: 10500 },
    { tenure: 2, autos: 200, price: 9500 },
    { tenure: 3, autos: 200, price: 8500 },
    { tenure: 6, autos: 200, price: 6500 },

    { tenure: .5, autos: 300, price: 12000 },
    { tenure: 1, autos: 300, price: 10000 },
    { tenure: 2, autos: 300, price: 9000 },
    { tenure: 3, autos: 300, price: 8000 },
    { tenure: 6, autos: 300, price: 6000 },

    { tenure: .5, autos: 500, price: 11500 },
    { tenure: 1, autos: 500, price: 9500 },
    { tenure: 2, autos: 500, price: 8500 },
    { tenure: 3, autos: 500, price: 7500 },
    { tenure: 6, autos: 500, price: 5500 },
  ]
  useEffect(() => {
    const CalculatePrice = () => {
      const matchedObject = PayableAmount.find(item => item.tenure === tenure && item.autos === autos);
      console.log("getting price table", tenure, autos)

      if (matchedObject) {
        const price = matchedObject.price;
        // setTotalCost(autos * tenure * costPerAuto)
        setCostPerAuto(price)



        console.log('Price:', price, tenure, autos);
      } else {
        console.log('No matching price found for the given tenure and autos value');
      }

    }
    CalculatePrice()

  }, [autos, tenure])
  const handleAutosChange = (event, newValue) => {
    setAutos(newValue);
  };

  const handleTenureChange = (event, newValue) => {
    setTenure(newValue);
  };
  // const handlePointClick = (point) => {
  //   // setValue(point);
  // }



  return (
    <div className='container'>
      <div className='row'>
        <h2 className="text-center p-5 you-can-calculate"> You can calculate the list rate for autorickshaws based on the number of autos and the duration</h2>
        <div className='slidermain'>
          <div className='col-md-2 col-sm-6'>
            <div className='sliderplan'>
              <span>NO. OF AUTOS</span>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='inputtext'>
              <input type='text' value={autos} onChange={e => setAutos(e.target.value)} />
            </div>
          </div>
          <div className='col-md-7'>
            <div style={{ width: "100%", }} className='rangeslider'>
              <Box sx={{ width: 600 }}>
                <Slider
                  aria-label="Custom marks"
                  value={autos}
                  onChange={handleAutosChange}
                  defaultValue={20}
                  getAriaValueText={valuetext}
                  step={null}
                  valueLabelDisplay="auto"
                  marks={marks}
                  min={20}
                  max={500}
                  sx={{
                    color: 'orange', // Set the color to orange
                    '& .MuiSlider-thumb': {
                      '&:hover, &.Mui-focusVisible': {
                        boxShadow: 'none', // Optional: Remove the shadow on hover/focus
                      },
                    },
                    '& .MuiSlider-valueLabel': {
                      color: 'orange', // Optional: Set the value label color to orange
                    },
                  }}
                />
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='slidermain'>
          <div className='col-md-2'>
            <div className='sliderplan'>
              <span>TENURE IN MONTHS</span>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='inputtext'>
              <input type='text' value={tenure} onChange={e => setTenure(e.target.value)} />
            </div>
          </div>
          <div className='col-md-7'>
            <div style={{ width: "100%", padding: '0 10px' }} className='rangeslider'>
              <Box sx={{ width: 600 }}>
                <Slider
                  aria-label="Custom marks"
                  defaultValue={0.5}
                  value={tenure}
                  onChange={handleTenureChange}
                  getAriaValueText={valuetext}
                  step={null}
                  valueLabelDisplay="auto"
                  marks={months}
                  min={0.5}
                  max={6}
                  sx={{
                    color: 'orange',
                    '& .MuiSlider-thumb': {
                      '&:hover, &.Mui-focusVisible': {
                        boxShadow: 'none',
                      },
                    },
                    '& .MuiSlider-valueLabel': {
                      color: 'orange',
                    },
                  }}
                />
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='slidermain'>
          <div className='col-md-2 col-sm-6'>
          </div>
          <div className='col-md-3'>
          </div>
          <div className='col-md-7'>
            <div className="price-calculation">
              <div className="price-container">
                <div className="result">

                  <p>Cost per Auto per month</p>
                  <div className="price-value">
                    <p style={{ fontSize: '23px', fontFamily: '"Poppins", sans-serif', color:'#f8c301', fontWeight:"bold" }}>Rs. {costPerAuto}</p>
                  </div>
                </div>
                <div className="result">
                  <p>Total cost of campaign </p>   
                  <div className="price-value">
                    <p style={{ fontSize: '23px', fontFamily: '"Poppins", sans-serif', color:'#f8c301', fontWeight:"bold" }}>Rs. {costPerAuto * tenure * autos}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscreteSliderMarks