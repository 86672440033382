import React, { useEffect, useState } from 'react'
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import Footer from "../components/footer/Footer";
import backImg_2 from "../images/backImg_2.jpg";
import Loader from "../components/pageloader/Loader";
import Navbar from '../components/navbar/Navbar'
import { Helmet } from 'react-helmet';
import PagesLink from '../components/pageslink/PagesLink';
import backGroundSubImg from '../images/Background.jpg'


const Background = () => {
    const [isLoader, setIsLoader] = useState(true)
    const TITLE = 'Background Autotisement';


    const imageUrls = [backImg_2]
    useEffect(() => {
      const preloadImages = async () => {
        const imagePromises = imageUrls.map((imageUrl) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = resolve;
          });
        });
    
        await Promise.all(imagePromises);
        setTimeout(() => { setIsLoader(false); }, 1000);
        };
    
      preloadImages();
    }, []);

    return (
        <>
            {isLoader ? (
                <Loader />
            ) : (
                <>
                    <Helmet>
                        <title>{TITLE}</title>
                    </Helmet>
                    <Navbar navbarcss='fixed' page='about' />
                    <AboutBanner heading="BACKGROUND" photo={backImg_2} />
                    <section className="about-content">
                        <div className="container-fluid p-2">
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-4">
                                        <PagesLink />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="about_content ">
                                            <h3>BACKGROUND</h3>
                                            <p>Autotisement has already gone to the market in 2009-2013 and had
                                                established a significant presence with its backlit autos in the OOH sector.
                                                At the time, we had a fleet of 400 backlit autos run by reliable drivers in
                                                Delhi. The vision was to eradicate the stigma about auto ads being
                                                unreliable and of poor quality, which is why reputed companies and brands
                                                didn’t deploy them to advertise their products.
                                            </p>
                                            <p>We did multiple campaigns with the names like Tata, Reliance, Pepsi, UTI,
                                                Kingston, etc., just to name a few.
                                            </p>
                                            <p>Such brands would never have even thought of using auto ads as an
                                                advertising medium if it were not for Autotisement, who made it possible to
                                                enable them to take their brand’s message to the masses with its backlit,
                                                reliable and high-quality fleet of autos.</p>
                                            <p>We discontinued operations in 2013 as the family business needed more
                                                attention at the time. Several other people, including some small agencies,
                                                tried to copy our backlit model but couldn’t sustain it for numerous reasons
                                                primarily due to their inability to manage drivers.
                                            </p>
                                            <p>Now Autotisement is being relaunched with an upgraded product while
                                                providing clients with a host of technology-based features.</p>
                                        </div>
                                        <div className="sub-heading-img">
                                            <img src={backGroundSubImg} alt="about-sub-head" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            )}
        </>
    )
}

export default Background