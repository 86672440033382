import React, { useEffect } from "react";
import "./loader.css";
import logo from "../../images/logo.png";
import { useState } from "react";
import { useLocation } from 'react-router-dom';

const Loader = () => {

  const [visible, setVisible] = useState(true);

  const location = useLocation();
  useEffect(() => {
    if(!visible){
      setVisible(true);
    }
    const timeout = setTimeout(() => {
        setVisible(false);
    }, 1000); 

    return () => {
      clearTimeout(timeout);
    };
  }, [location.pathname]);

  return visible ? <>
      <div className="zoom-in-zoom-out">
        <div className="zoom">
          <img src={logo} alt="" />
        </div> 
      </div>
    </> : null ;
  
};

export default Loader;
