import React from 'react';
import './matter.css'

const Matter = (props) => {
    const isManishaPandey = window.location.pathname === '/manisha-pandey';
    return (
        <>
            <section className='matter' style={{
                backgroundImage: `url(${props.photo})`, width: '100%', height: "120vh", position: 'relative', marginTop: '40%', backgroundPosition: 'center', backgroundSize: 'cover'
            }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>

                            {isManishaPandey ?
                                <>
                                    <div className='pain'>
                                        <div className='manisha-content'>
                                            <h4 className={props.matterHeading}>{props.Matter}</h4>
                                            <h1 className={props.className}>{props.heading}</h1>
                                        </div>
                                    </div>

                                </>
                                :
                                <>
                                    <div className='content'>
                                        <h4 className={props.matterHeading}>{props.Matter}</h4>
                                        <h1 className={props.className}>{props.heading}</h1>
                                    </div></>}
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}

export default Matter;
