import React,{useEffect, useState} from "react";
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import Footer from "../components/footer/Footer";
import AboutContent from "../components/aboutcoponent/AboutContent";
// import Image from "../images/aboutbanner.jpg";
import Navbar from '../components/navbar/Navbar'
import { Helmet } from 'react-helmet';
import Loader from "../components/pageloader/Loader";
const AboutPage = () => {
  const [isLoader,setIsLoader]=useState(true)
  const TITLE = 'About Autotisement';

  const imageUrls = ["https://res.cloudinary.com/dznncrjxh/image/upload/v1693546519/aboutbanner_fiaxcy.jpg"]; // Add all image URLs to preload here

  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = imageUrls.map((imageUrl) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = resolve;
        });
      });
  
      await Promise.all(imagePromises);
      setTimeout(() => { setIsLoader(false); }, 1000);
      };
  
    preloadImages();
  }, []);
 

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      {
        isLoader ? <>
        <Loader/>
        </> :
          <>
            <Navbar navbarcss='fixed' page='about' />
            <AboutBanner heading="ABOUT" photo={"https://res.cloudinary.com/dznncrjxh/image/upload/v1693546519/aboutbanner_fiaxcy.jpg"} />
            <AboutContent />
            <Footer />
          </>
      }

    </>
  );
};

export default AboutPage;
