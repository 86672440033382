import React from "react";
import "./serviceontent.css";

const ServiceContent = () => {
  return (
    <>
      <section className="servicecontent">
        <div className="container">
          <div className="row  p-md-5 margin-bottom">
            <div className="col-md-6">
              <div className="event content">
                <h2>AUTOS VERSUS TAXIS</h2>
                <p>
                  More potent weapons of outdoor advertising than the radio
                  taxis are
                </p>
                <ol>
                  <li>Autos have back display, taxis on the side</li>
                  <li>Autos are backlit, radio taxis are not</li>
                  <li>Taxis are below the line of sight, autos are in your face</li>
                  <li>Autos go everywhere including posh areas and airports</li>
                  <li>Taxis zoom fast, autos are slow – more time to view</li>
                </ol>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-img-1"></div>
            </div>
          </div>
          <div className="row p-md-5 margin-bottom">
            <div className="col-md-6 order-2 order-md-1">
              <div className="service-img-2"></div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="event content">
                <h2>AUTOS VERSUS HOARDINGS</h2>
                <p>
                  More potent weapons of outdoor advertising than the radio
                  taxis are
                </p>
                <ol>
                  <li>Hoardings are static, autos are mobile.</li>
                  <li>Pole kiosks are placed too high, autos are in your face.</li>
                  <li>
                    Bus shelter displays crane your neck. Thats pain in the neck
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="row p-md-5 margin-bottom">
            <div className="col-md-6">
              <div className="content">
                <h2>AUTOS VERSUS NEWSPAPERS</h2>
                <p>
                  Both media are complementary. Use autos to grab eyeballs
                  everyday whereas newspaper advertisements are good for
                  printing details periodically. To launch a campaign or a
                  brand, for example, a half or full page advertisement in a
                  leading daily should do the trick but also put 250 autos on
                  the road for a month at similar cost for brand recall every
                  day.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-img-3"></div>
            </div>
          </div>
          <div className="row p-md-5 margin-bottom">
            <div className="col-md-6 order-2 order-md-1">
              <div className="service-img-4"></div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="event content">
                <h2>AUTOS FOR EVENTS</h2>

                <ol>
                  <li>
                    A leading brand had organised multiple roadshows with autos
                    carrying its advertisements.
                  </li>
                  <li>
                    A premium car company had hired 25 branded autos to give
                    their senior managers a taste of India Experience.
                  </li>
                  <li>
                    NACO had invited 70 drivers with branded autorickshaws to
                    participate in their high profile event attended by a
                    Cabinet Minister.
                  </li>
                  <li>
                    An exhibition company had used branded autos to ferry
                    visitors between the gates/parking and the venue.
                  </li>
                 
                </ol>
              </div>
            </div>
          </div>
          <div className="row margin-bottom">
            <div className="col-md-12">
              <div className="last-content">
                <p>
                  So, why did they do it? Because a single auto covered with
                  branding at your event stands out! When you multiply that
                  number with 20 or 50, the visibility and the curiosity factory
                  is just amazing!
                </p>
                <p>
                  Let us sit together to figure out how you could benefit from
                  the participation of branded autorickshaws in your brand
                  building events. I am sure the meeting will throw up something
                  innovative.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceContent;
