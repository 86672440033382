import React, { useEffect, useState } from "react";
import Loader from "../components/pageloader/Loader";
import Footer from "../components/footer/Footer";
import First from "../components/Blog/First";
import Navbar from "../components/navbar/Navbar";

const FourBlog = () => {
  const [loder, setLoder] = useState(true);

  const loading = () => {
    setTimeout(() => {
      setLoder(false);
    }, 1000);
  };

  useEffect(() => {
    loading();
  }, [loder]);
  return (
    <>
      {loder ? (
        <Loader />
      ) : (
        <>
          <Navbar navbarcss='fixed' page='blog' />
          <First
            mainheading=" lorem ipsum dolar sirt amet conetnt lorem generate "
            heading="lorem ipsum dolar sirt amet conetnt lorem generate lorem
                    ipsum dolar sirt amet conetnt lorem generate by lorem ipsum"
            firstline="The International Day of Happiness was established by the
                    United Nations in 2012 to recognize the importance of
                    happiness and well-being as universal goals and aspirations
                    in the lives of people around the world. This day reminds us
                    of the importance of positive relationships, kindness, and
                    meaningful connections with others."
            secondline=" Similarly, World Oral Health Day, established by the FDI
                    World Dental Federation, is celebrated annually on March
                    20th to raise awareness about the importance of oral health
                    and hygiene. This year’s theme is “Be Proud of Your Mouth,”
                    emphasizing the significance of maintaining good oral health
                    for overall well-being."
            thirdline="  At Unspoken Smiles, a nonprofit organization that provides
                    access to dental care for children in underserved
                    communities, we understand the critical role that oral
                    health plays in overall health. Poor oral health can lead to
                    numerous health problems, including heart disease, stroke,
                    and diabetes."
            fourthline="The power of prevention in fighting oral diseases cannot be
                    overstated. Regular brushing, flossing, and dental checkups
                    can prevent oral health problems from developing and
                    becoming more severe. Additionally, a healthy diet that is
                    low in sugar and high in nutrients can also help maintain
                    good oral health."
            date="10.5.2023"
          />
          <Footer />
        </>
      )}
    </>
  );
};

export default FourBlog;
