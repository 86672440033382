import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar/Navbar";
import "./landingpage.css";
import Footer from "../components/footer/Footer";
import ProgressBar from "../components/progressbar/ProgressBar";
import { Helmet } from 'react-helmet';
import Loader from "../components/pageloader/Loader";
import prahaladKakkar from '../images/Prahlad Kakkar Image.jpeg'
import Square2 from '../images/square2.jpg'
import square1 from '../images/homeSquare.jpeg'
import square4 from '../images/sqaure5.jpg'
import square5 from '../images/square4.jpg'
import square6 from '../images/auto5.jpg'


const LandingPage = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [progressPercent, setProgressPercent] = useState(0);
  const [isLoader, setIsLoader] = useState(true)
  const imageUrls = [prahaladKakkar,square6,square1]; 

useEffect(() => {
  const preloadImages = async () => {
    const imagePromises = imageUrls.map((imageUrl) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = resolve;
      });
    });

    await Promise.all(imagePromises);
  };

  preloadImages();
}, []);
  useEffect(() => {
    const handleScroll = (event) => {
      const nextSectionIndex = Math.floor(window.scrollY / window.innerHeight);
      const progress = window.scrollY % window.innerHeight;
      const progressPercent = Math.floor((progress / window.innerHeight) * 100);
      setProgressPercent(progressPercent);
      if (nextSectionIndex !== currentSection) {
        setCurrentSection(nextSectionIndex);
      }
      event.preventDefault();
    };

    window.addEventListener("scroll", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentSection]);

  console.log()
  const TITLE = 'Home Autotisement';
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoader(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };

  }, [])

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      {isLoader ? <Loader /> :
        <>
          <Navbar navbarcss="navbarcss" />
          <ProgressBar progress={progressPercent} />
          <main className="landingmain">
            <section
              data-index="1"
              className={
                currentSection === 0 ? "firstsection active" : "firstsection"
              }
            >
              <div className="homecontent">
                {/* <div className="square" style={{backgroundImage:`{url(square1)}`}}></div> */}
                <div className="square" style={{ backgroundImage: `url(${square1})`,backgroundSize:"cover" }}></div>


                <h1
                  className="animate-charcter"
                  style={{
                    transform: `translate(-50%,-${progressPercent === 0 ? "51" : 51 + progressPercent / 5
                      }%)`,
                    transition: "transform 0.5s ease-in-out",
                  }}
                >
                  Our autos are back-lit
                </h1>
                <p>
                  We have custom-made the auto hood in FRP ( Fire Reinforced
                  Plastic) and added backlighting to the back and two side
                  panels. No other company can offer backlighting.
                </p>
              </div>
            </section>
            <section
              data-index="2"
              className={
                currentSection === 1 ? "secsection active" : "secsection"
              }
            >
              <div className="homecontent">
                <div className="square"></div>
                <h1
                  className="animate-charcter"
                  style={{
                    transform: `translate(-50%,-${progressPercent === 0 ? "51" : 51 + progressPercent / 5
                      }%)`,
                    transition: "transform 0.5s ease-in-out",
                  }}
                >
                  We invented auto ads industry
                </h1>
                <p>
                  In 2009, Nyayabhoomi was the first NGO to use autos for
                  advertising. We inherited their legacy. Its founder-secretary
                  is our mentor whose son is the company’s co-founder.
                </p>
              </div>
            </section>
            <section
              data-index="3"
              className={
                currentSection === 2 ? "thirdsection active" : "thirdsection"
              }
            >
              {/* <div className="homecontent"> */}
                <div className="most-prominent-section">
                  <div className="most-prominent-text">
                    <h2>
                      Most potent weapon of outdoor advertising
                    </h2>
                  </div>
                  <div className="prahlad-kakar">
                    {/* <img src={prahaladKakkar} alt="most-prominent-people"></img> */}

                  </div>
                </div>
                <p>
                  Unlike a static hoarding, an auto is highly mobile and takes
                  your message far and wide. It even goes to the airports where
                  outdoor hoarding sites are very expensive.
                </p>
              {/* </div> */}
            </section>
            <section
              data-index="4"
              className={
                currentSection === 3 ? "foursection active" : "foursection"
              }
            >
              <div className="homecontent">
                <div className="square"></div>
                <h1
                  className="animate-charcter"
                  style={{
                    transform: `translate(-50%,-${progressPercent === 0 ? "51" : 51 + progressPercent / 5
                      }%)`,
                    transition: "transform 0.5s ease-in-out",
                  }}
                >
                  Going to every nook and corner of the city
                </h1>
                <p>
                  Autorickshaws are used by lower and upper economic classes but
                  owned and driven by and parked at areas of lower income
                  classes, giving you 360 degree reach all over the city.
                </p>
              </div>
            </section>
            <section
              data-index="5"
              className={
                currentSection === 4 ? "fivesection active" : "fivesection"
              }
            >
              <div className="homecontent">
                <div className="square"></div>
                <h1
                  className="animate-charcter"
                  style={{
                    transform: `translate(-50%,-${progressPercent === 0 ? "51" : 51 + progressPercent / 5
                      }%)`,
                    transition: "transform 0.5s ease-in-out",
                  }}
                >
                  Digital autos and e-rickshaws coming soon
                </h1>
                <p>
                  Our next version is TV-style digital displays which are
                  directly controlled by clients from the comfort of their
                  offices. Ads can be changed on the fly whenever one wants.
                </p>
              </div>
            </section>
            <section
              data-index="6"
              className={
                (currentSection === 5 || currentSection === 6) ? "sixsection active" : "sixsection"
              }
            >
              <div className="homecontent">
                <div className="square"></div>
                <div className="square" style={{ backgroundImage: `url(${square6})`,backgroundSize:"cover" }}></div>

                <h1
                  className="animate-charcter"
                  style={{
                    transform: `translate(-50%,-${progressPercent === 0 ? "51" : 51 + progressPercent / 5
                      }%)`,
                    transition: "transform 0.5s ease-in-out",
                  }}
                >
                  Traffic on the road is captive audience
                </h1>
                <p>
                  An auto carries 25-30 passengers every day who cannot help
                  noticing the unique design of the vehicle. Besides, when
                  driving or being driven on the road, you read everything that
                  comes your way.
                </p>
              </div>
            </section>
            <div className="landingfooter">
              <Footer />
            </div>
          </main>

        </>
      }

    </>
  );
};

export default LandingPage;
