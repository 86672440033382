import React, { useEffect, useState } from "react";
import First from "../TeamComponent/First";
import firstbanner from "../images/AmanGupta.jpg";
import TeamAbout from "../TeamComponent/TeamAbout";
import Footer from "../components/footer/Footer";
import Loader from "../components/pageloader/Loader";
import Navbar from "../components/navbar/Navbar";
import Socialmedia from "../components/teamcomponent/Socialmedia";
import Matter from "../components/teamcomponent/Matter";
import Secondbanner from '../images/Amanuniversity.jpg'
import Progress from "../components/teamcomponent/Progress";



const ThirdPage = () => {
  const [loder, setLoder] = useState(true);

  const loading = () => {
    setTimeout(() => {
      setLoder(false);
    }, 1000);
  };

  useEffect(() => {
    loading();
  }, [loder]);
  return (
    <>
      {loder ? (
        <Loader />
      ) : (
        <>
      <Navbar navbarcss='fixed' page='team' />
          <First
            photo={firstbanner}
            intro="Introducing"
            heading="AMAN GUPTA"
            paragraph="Co-Founder"
          />
          <TeamAbout
            teamname=" ABOUT AMAN GUPTA"
            about="Passionate and driven, Aman is a financial analyst with a diverse background in finance and technology.Known for his superior problem-solving skills and hard work, he thrives in collaborative environments, leveraging excellent communication and interpersonal skills to build meaningful relationships and foster teamwork.Aman's dedication, integrity, and genuine passion make him an asset in any setting especially at Autotisement where he contributes his global insights and experiences to look for new opportunities for growth, impact, and collaboration in pursuit of making a meaningful contribution to the world."
            thought={`“मन के हारे हार हैं,\n मन के जीते जीत”`}
            writter=" - Aman Gupta"
          />
          <Socialmedia
            email="a.gupta@autotisement.in"
            phone="+971-569543145"
            facebook="https://www.facebook.com/amngupta "
            linkedin="https://www.linkedin.com/in/amngupta"
          />
          <Matter className="h1" photo={Secondbanner} Matter='NOSTALGIC ABOUT' heading='HONG KONG UNIVERSITY'/>
          <section className='Teamabout'>
            <div className='container'>
            <h3>WHERE DO I STAND</h3>
              <div className='row'>
                <div className='col-md-12'>
                  <Progress progress={95} content="Technology" bar='ACE' />
                  <Progress progress={90} content="Coding" bar='Strong' />
                  <Progress progress={98} content="Internet security" bar='Perfect' />
                  <Progress progress={95} content="Thirst For Innovation" bar='Insatable' />
                  <Progress progress={50} content="Leadership" bar='Too Soft' />
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default ThirdPage;
