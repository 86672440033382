import React from "react";
import "./aboutbanner.css";
import Logo from '../../images/logo.png'
import { Link } from "react-router-dom";

const AboutBanner = (props) => {
  return (
    <>
      <div
        id="about-banner"
        className="aboutbanner"
        style={{
          backgroundImage: `url(${props.photo})`,
        }}
      >
        {/* <div className="logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div> */}
        <div className="content">
          <h1>{props.heading}</h1>
        </div>
      </div>
    </>
  );
};

export default AboutBanner;
