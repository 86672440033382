import React from "react";
import "./careercontent.css";

const CareerContent = () => {
  return (
    <>
      <section className="careercontent">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading">
                <h1> DISRUPTING THE AD INDUSTRY</h1>
                <p>
                  No doubt, Autotisement is disrupting the ad industry in a long
                  while. Printed and pasted hoardings have become digital and
                  there is a memorable new campaign once in a while. Sure
                  enough, colour has taken over black & white in newspapers. But
                  the real change in the industry has been non-existent
                  otherwise and happens in a blue moon. If you want to join us
                  to change the status quo, read on.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerContent;
