import React, { useEffect, useState } from "react";
import First from "../TeamComponent/First";
import fivebanner from "../images/PrahladKakkar.jpeg";
import TeamAbout from "../TeamComponent/TeamAbout";
import Footer from "../components/footer/Footer";
import Loader from "../components/pageloader/Loader";
import Navbar from "../components/navbar/Navbar";


const FivePage = () => {
  const [loader, setLoader] = useState(true);

  const imageUrls = [fivebanner]; 

  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = imageUrls.map((imageUrl) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = resolve;
        });
      });
  
      await Promise.all(imagePromises);
      setTimeout(() => { setLoader(false); }, 1000);
    };
  
    preloadImages();
  }, []);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Navbar navbarcss='fixed' page='team' />
          <First
            photo={fivebanner}
            intro="Introducing"
            heading="PRAHLAD KAKKAR"
            paragraph="Mentor"
          />
          <TeamAbout
            teamname=" ABOUT Prahalad Kakkar"
            about="Known as an “Ad guru”, he has been instrumental in changing the face of advertising in 
            our country The lens with which he views the world is simply several leagues apart. He 
            has always played on the front foot in taking any risks and virtually invented a genre of 
            television advertising in India. He has ruled the advertising world with his brand of 
            irreverent humor, memorable brand building campaigns and has created Award winning 
            commercials for the most reputed Corporates and agencies for over 4 decades such as 
            Pepsi “Yehi heh right choice baby! Aha” Gold spot “The zing thing”, Maggie “2 minutes?” 
            Britannia “ting ting ting”, Limca “Zor ka Jhatka” and Maggi Sauces “Its different!”. We are 
            fortunate to have him on board as a mentor and the guiding force in revolutionizing the 
            OOH industry across the world.
            "
            thought="“The time for playing safe is gone, now is the time to take risks, the world is opening up to you and will accept you.”"
            writter=" - Prahalad Kakkar"
          />
          
          <Footer />
        </>
      )}
    </>
  );
};

export default FivePage;
