import React from "react";
import "./teamcontent.css";

const TeamContent = (props) => {
  return (
    <>
      <section className="teamcontent">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="content">
                <h1 className="newVision">
                  {props.first}
                  <br></br>
                  {props.second}
                </h1>
                <p>{props.paragraph}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamContent;
