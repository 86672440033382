import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import First from "../components/Blog/First";
import Footer from "../components/footer/Footer";
import Loader from "../components/pageloader/Loader";
import Navbar from "../components/navbar/Navbar";
import firstBlogImg from '../images/world-oral-health-day.jpg'
  
const FirstBlog = () => {
  const [loder, setLoder] = useState(true);
  const [card,setCard]=useState([])
  const { id } = useParams()

  const loading = () => {
    setTimeout(() => {
      setLoder(false);
    }, 1000);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`https://autotisement.in/api/v1/blog/${id}`, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result= await response.json();
        console.log(result.BlogTitle);
        setCard(result);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [id])

  useEffect(() => {
    loading();
  }, [loder]);
  return (
    <>
      {loder ? (
        <Loader />
      ) : ( 
        <>
          <Navbar navbarcss='fixed' page='blog' />
          <First
            mainheading={card.ImageUrl}
            heading={card.blogHeading}
            firstline={card.firstline}
            secondline={card.secondline}
            thirdline={card.thirdline}
            fourthline={card.fourthline}
            date={card?.createdAt?.slice(0,10)}
          />
          <Footer />
        </>
      )}
    </>
  );
};

export default FirstBlog;
