import React from 'react';
import './teamabout.css';
import Progress from './Progress';

const Teamabout = () => {
    return (
        <>
            <section className='Teamabout'>
                <div className='container'>
                <h3>WHERE DO I STAND</h3>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Progress progress={100} content="Business acumen" bar='PERFECT' />
                            <Progress progress={100} content="Hustling" bar='ENT PERCENT ' />
                            <Progress progress={60} content="Snooker" bar='NOT BAD' />
                            <Progress progress={90} content="Communication" bar='ALMOST FULL' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Teamabout;
