import React from "react";
import "./teamcontent.css";

const Teamhead = (props) => {
  return (
    <>
      <div className="teamhead">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <span className="">{props.intro}</span>
              <h1 className="leadership">{props.leadership}</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teamhead;
