import React from "react";

const GoogleMap = () => {
  const mapUrl =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.8634558639765!2d77.24129537469365!3d28.63385418402213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfdeecff35859%3A0x68d7584c34f7679c!2sAutotisement%20Adtech%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1685439022032!5m2!1sen!2sin";

  return (
    <>
      <div className="map" style={{ marginBottom: "-6px" }}>
        <iframe
          src={mapUrl}
          width="100%"
          height="900"
          frameborder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </>
  );
};

export default GoogleMap;
