import React, { useEffect, useState } from 'react'
import AboutBanner from "../components/aboutcoponent/AboutBanner";
import Footer from "../components/footer/Footer";
// import Image from "../images/aboutbanner.jpg";
import concerImg from '../images/concernImg.jpg'
import Loader from "../components/pageloader/Loader";
import Navbar from '../components/navbar/Navbar'
import { Helmet } from 'react-helmet';
import PagesLink from '../components/pageslink/PagesLink';
import concernSubImg from '../images/auto6.jpg'

const Concernpage = () => {
    const [isLoader, setIsLoader] = useState(true)
    const TITLE = 'Concern Autotisement';

    const imageUrls = [concerImg]
    useEffect(() => {
        const preloadImages = async () => {
            const imagePromises = imageUrls.map((imageUrl) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.src = imageUrl;
                    img.onload = resolve;
                });
            });

            await Promise.all(imagePromises);
            setTimeout(() => { setIsLoader(false); }, 1000);
        };

        preloadImages();
    }, []);


    //   useEffect(() => {
    //     const timeout = setTimeout(() => {
    //       setIsLoader(false);
    //     }, 1000);

    //     return () => {
    //       clearTimeout(timeout);
    //     };

    //   }, [])

    return (
        <>
            {isLoader ? (
                <Loader />
            ) : (
                <>
                    <Helmet>
                        <title>{TITLE}</title>
                    </Helmet>
                    <Navbar navbarcss='fixed' page='about' />
                    <AboutBanner heading="CONCERNS " photo={concerImg} />
                    <section className="about-content">
                        <div className="container-fluid p-2">
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-4">
                                        <PagesLink />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="about_content ">
                                            <h3>CONCERNS</h3>
                                            <p>
                                                Auto ads are considered BTL (Below The Line) and
                                            </p>
                                            <ol type='i'>
                                                <li>Are of <strong>low quality</strong> (no innovation),</li>
                                                <li><strong>Unverifiable</strong> (photoshopped), and</li>
                                                <li>Agencies <strong>no control over vehicles</strong> (they pay nothing to drivers).</li>
                                            </ol>
                                            <p>
                                                Our mission is to address these 3 concerns so that auto ads can be used by reputed brands,
                                                MNCs and startups at better prices who shy away from auto ads at present.
                                            </p>
                                        </div>
                                        <div className="sub-heading-img">
                                            <img src={concernSubImg} alt="about-sub-head" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            )}
        </>
    )
}

export default Concernpage